import { useMutation, useQuery, useQueryClient } from "react-query";
import { Auth, IProps } from "../../utils/interface";
import ENDPOINTS from "../services/endPoints";
import { mutatePostFn, mutatePutFn, QueryReturnType } from "../services/networking.service";

export const useGetProfileDetail = ({ id }: IProps): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.PROFILE_DETAIL, { id: id }],
        {
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
};

export const useEditProfile = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, { id: string, username: string }>(data => mutatePutFn(ENDPOINTS.PROFILE_EDIT, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useChangePassword = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, { _id: string, oldPassword: string, newPassword: string }>(data => mutatePostFn(ENDPOINTS.CHANGE_PASSWORD, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}