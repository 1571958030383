import { useMutation, useQueryClient } from "react-query"
import { mutatePostFn } from "../services/networking.service"
import ENDPOINTS from "../services/endPoints"
import { Auth, ILogin } from "../../utils/interface"

export const useAdminLogin = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, ILogin>(data => mutatePostFn(ENDPOINTS.ADMIN_LOGIN, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}