import React from 'react'
import Wrapper from '../../components/header/headerWrapper'
import InProgress from '../../components/pageNotFound/inProgress'
import RoutePath from '../../routes/routesPath';

const Notifications: React.FC = () => {
    return (
        <Wrapper title={RoutePath.Notifications.title}>
            <InProgress />
        </Wrapper>
    )
};
export default Notifications