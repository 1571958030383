import { InputProps } from "../../utils/interface";

export const CustomInput: React.FC<InputProps> = ({
    name,
    type,
    onBlur,
    onChange,
    placeholder,
    value,
    className,
    errors,
    touched,
    onKeyPress,
    maxLength,
    onkeyDown,
    max,
    min }) => {
    return (
        <>
            <input
                min={min}
                max={max}
                type={type}
                value={value}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                onBlur={onBlur}
                className={className}
                onKeyPress={onKeyPress}
                maxLength={maxLength}
                onKeyDown={onkeyDown}
            />
            {errors && touched && <p className="error">{errors}</p>}
        </>
    )
};