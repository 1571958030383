/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { CustomInput } from "../../components/common/input";
import { Strings } from "../../utils/constants/strings";

const DeleteAccount: React.FC = () => {
  return (
    <div className="d-flex flex-column flex-root vh-100">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative">
          <div
            className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px "
            style={{ backgroundColor: "inherit" }}>
            <div className="d-flex flex-row-fluid flex-column text-center p-5 p-lg-10 pt-lg-20">
              <a className="py-2 py-lg-20">
                <img
                  alt="Logo"
                  src="/assets/media/main-logos/GlucoLogo.svg"
                  className="h-90px h-lg-100px"
                />
              </a>
              <h1 className="d-none d-lg-block fw-bold text-white fs-2qx pb-5 pb-md-10">
                Send otp to email
              </h1>
            
            </div>
            <div
              className="d-none d-lg-block d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
              style={{ backgroundImage: "url(/assets/media/illustrations/verification.png)" }} />
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row-fluid py-10 login-bg">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-xl-700px w-100 p-10 p-lg-15 mx-auto z-1">
              <form  className="form w-100 bg-white p-8 rounded-3 " >
                <div className="text-center mb-6">
                  <h1 className="text-dark fs-3">Verify Email</h1>
                </div>
                <div className=" mb-10 fv-plugins-icon-container">
                  <label className="form-label fs-6 fw-bold text-dark">
                    {Strings.email}*
                  </label>
                  <CustomInput
                    className="form-control form-control-lg form-control-solid"
                    value={""}
                    name='email'
                    type="email"
                    onChange={()=>{}}
                    onBlur={()=>{}}
                    errors={"email"}
                    touched={"email"}
                    placeholder='Enter Your Email'
                  />
                </div>
                <div className="text-center">
                  <button
                    disabled={false}
                    type="submit"
                    className="btn btn-lg btn-primary w-100 mb-5">
                   Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
export default DeleteAccount