/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import IMAGES from "../../../utils/constants/images";
import { IProductListProps } from "../../../utils/interface";
import { DeleteIcon, EditIcon } from "../../common/svg";

type Props = {
    data: IProductListProps[];
    onClickDetail: (data: IProductListProps) => void;
    handleToggleStaus: (data: IProductListProps) => void;
    handleDeleteProduct: (_id: string) => void;
    handleModalOpen: (e: React.MouseEvent<HTMLButtonElement>, data: IProductListProps) => void;
};

const ProductListing: React.FC<Props> = ({
    data,
    onClickDetail,
    handleToggleStaus,
    handleDeleteProduct,
    handleModalOpen,
}: Props) => {
    return (
        <>
            {data?.map((items, index) => (
                <tr key={index} className="table-content">
                    <td>
                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                            <div onClick={() => window.open(items.productImage)} className="symbol-label">
                                <img
                                    src={items.productImage}
                                    alt="Product"
                                    className="w-100"
                                />
                            </div>
                        </div>
                    </td>
                    <td>{items.productName}</td>
                    <td>${items.price}</td>
                    <td>
                        <a onClick={() => window.open(items.productLink)} className="text-decoration-underline cursor-pointer">
                            {items.productLink}
                        </a>
                    </td>
                    <td>
                        <span
                            className={
                                items?.status === "1"
                                    ? "text-success fw-bolder"
                                    : "text-danger fw-bolder"
                            }>
                            {items?.status === "1" ? "Active" : "Suspended"}
                        </span>
                    </td>
                    <td>
                        <span className="icons-main">
                            <img
                                onClick={() => onClickDetail(items)}
                                width={19}
                                alt="View"
                                src={IMAGES.ICONS.viewIcon}
                            />
                            <button title="Edit" onClick={(e) => handleModalOpen(e, items)} className="table-trash">
                                <EditIcon />
                            </button>
                            <button onClick={() => handleDeleteProduct(items._id)} className="table-trash">
                                <DeleteIcon />
                            </button>
                            <span
                                onClick={() => handleToggleStaus(items)}
                                className="table-check">
                                <input
                                    type="checkbox"
                                    id={items._id}
                                    checked={items?.status === "1"}
                                    readOnly
                                />
                                <label htmlFor={items._id} />
                            </span>
                        </span>
                    </td>
                </tr>
            ))}
        </>
    );
};
export default ProductListing;