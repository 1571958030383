import React from "react";
import Wrapper from "../../components/header/headerWrapper";
import RoutePath from "../../routes/routesPath";
import ContentScreen from "../../components/screens/content/contentScreen";
import TermsAndConditons from "../termsAndConditions";
import PrivacyPolicy from "../privacyPolicy";
import AboutUs from "../AboutUs";
import { useGetContents } from "../../react-query/hooks/contents";

const ContentManagement: React.FC = () => {
  // Hook to fetch contents
  const { data: getContents } = useGetContents();

  // State variable 'contentPath' initialized with default route path
  const [contentPath, setContentPath] = React.useState(
    RoutePath.TermsAndCondition.path
  );

  // Function to determine which component to render based on 'path'
  function isLinkNavigate() {
    if (contentPath === RoutePath.TermsAndCondition.path) {
      return <TermsAndConditons data={getContents?.data} />;
    } else if (contentPath === RoutePath.PrivacyAndPolicy.path) {
      return <PrivacyPolicy data={getContents?.data} />;
    } else if (contentPath === RoutePath.About.path) {
      return <AboutUs data={getContents?.data} />;
    }
    return <TermsAndConditons data={getContents.data} />;
  }

  // Function to handle click events on tabs and update 'path' state
  function handleContentChange(path: string) {
    setContentPath(path);
  }

  // Function to handle click events on tabs and update 'path' state
  function isLinkActive(pathname: string) {
    return pathname === contentPath;
  }

  return (
    <Wrapper title={RoutePath.ContentManagement.title}>
      <ContentScreen
        contentPath={contentPath}
        data={getContents?.data}
        handleContentChange={handleContentChange}
        isLinkActive={isLinkActive}
      >
        {isLinkNavigate()}
      </ContentScreen>
    </Wrapper>
  );
};
export default ContentManagement;
