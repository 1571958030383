import IMAGES from "../../utils/constants/images";

const PageNotFound = () => {
    return (
        <div className="d-flex flex-column flex-center flex-column-fluid p-10 vh-100">
            <img
                alt=""
                src={IMAGES.LOGO.pageNotFoungLogo}
                className="mw-100 mb-10 h-lg-450px"
            />
            <h1 className="fw-bold mb-10" style={{ color: "#A3A3C7" }}>
                Seems there is nothing here
            </h1>
        </div>
    );
};

export default PageNotFound;
