import { QueryClientProvider } from 'react-query'
import './App.css'
import { queryClient } from './app/react-query/services/networking.service'
import { AuthProvider } from './app/context/authContext'
import Navigation from './app/routes';
import { ToastContainer } from 'react-custom-alert';
import 'react-custom-alert/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import LogoutSession from './app/utils/logoutSession';
import React from 'react';

function App() {
  // handle function for logout when Session expired 
  const Logout = React.useCallback(() => {
    return <LogoutSession />
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Navigation />
        <ToastContainer floatingTime={1500} />
        <Logout />
      </AuthProvider>
    </QueryClientProvider>
  )
}
export default App;