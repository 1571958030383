const IMAGES = {
    LOGO: {
        pageNotFoungLogo: "assets/media/illustrations/404-hd.png",
        welcomeLogo: "assets/media/main-logos/GlucoV2.gif",
        glucoLogo: "assets/media/main-logos/GlucoLogo.svg",
        mainLogo: "assets/media/logos/side-bar-logo.svg",
        notificationLogo: "assets/media/icons/notify.svg",
        profileLogo: "assets/media/avatars/profile.png",
        profileDefaultLogo: "assets/media/logos/profile.png",
        organizationLogo: "assets/media/logos/default.png",
    },
    ICONS: {
        backIcon: 'assets/media/icons/go-back-arrow.png',
        viewIcon: 'assets/media/icons/info-icon.webp',
        dropDownIcon: 'assets/media/icons/dropdown.svg',
        dropUpIcon: 'assets/media/icons/dropup.svg',
    },
    BACKGOURND: {
        onProgress: 'assets/media/illustrations/workOnProgress.jpg',
    },
}

export default IMAGES