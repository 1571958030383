import React from 'react'

type Props = {
    title: string
    value: string
    placeholder: string
    name: string
    onChange: any
    onBlur: any
    error: any
    touched: any
    onKeyPress?: any
}

const ModalInputs = ({ error, name, onBlur, onChange, placeholder, title, touched, value, onKeyPress }: Props) => {
    return (
        <div className="col-lg-12">
            <div className="common-input">
                <label>{title}</label>
                <input
                    onKeyPress={onKeyPress}
                    name={name}
                    type={'text'}
                    className="form-control"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    autoComplete='off'
                    placeholder={placeholder} />
                {error && touched && <p className='error'>{error}</p>}
            </div>
        </div>
    )
};
export default ModalInputs