import React from "react";
import IMAGES from "../../../utils/constants/images";
import { Strings } from "../../../utils/constants/strings";
import ModalInputs from "../../common/modalInputs";

type IProps = {
  onChange: any;
  onBlur: any;
  error: any;
  touched: any;
  value: any;
  handleImageChange: (e: any) => void;
  imageLink: File;
  selectedImageLink: string;
  videoLink: File;
  selectedVideoLink: string;
};

const BlogModal: React.FC<IProps> = ({
  onChange,
  onBlur,
  error,
  touched,
  value,
  handleImageChange,
  imageLink,
  videoLink,
  selectedImageLink,
  selectedVideoLink,
}) => {
  const fileInputRef = React.useRef<any>(null);
  const videoRef = React.useRef<any>(null);

  function handleSelectFromLibrary() {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  // Function to render conditional image display

  function renderImage() {
    if (imageLink || videoLink || selectedImageLink) {
      const blogImageUrl = imageLink
        ? URL.createObjectURL(imageLink)
        : videoLink
        ? URL.createObjectURL(videoLink)
        : selectedImageLink && selectedImageLink;

      return <img width={"100%"} height={"100%"} src={blogImageUrl} alt="" />;
    } else if (selectedVideoLink) {
      return (
        <video
          ref={videoRef}
          src={selectedVideoLink}
          style={{ width: "100%", height: "100%" }}
        />
      );
    } else return <img src={IMAGES.LOGO.organizationLogo} alt="Img" />;
  }

  return (
    <div className="modal-flex-blk">
      <div className="modal-img-blk">
        {renderImage()}
        <div className="modal-upld-file">
          <input
            onChange={handleImageChange}
            type="file"
            accept="*"
            ref={fileInputRef}
            className="d-none"
          />
          <label onClick={handleSelectFromLibrary}>
            <span className="upld-texticon">Click here to upload</span>
          </label>
        </div>
        {error.image && touched.image && (
          <p className="error mt-4">{error.image}</p>
        )}
      </div>
      <div className="modal-form-blk">
        <div className="row gy-4">
          <ModalInputs
            name="title"
            onBlur={onBlur}
            onChange={onChange}
            error={error.title}
            touched={touched.title}
            title={Strings.blog_title}
            value={value.title}
            placeholder="Enter Blog Title"
          />
          <div className="col-lg-12">
            <div className="common-input">
              <label>{Strings.blog_description}</label>
              <textarea
                placeholder="Enter Blog description"
                className="form-control"
                rows={4}
                onBlur={onBlur}
                onChange={onChange}
                name="description"
                value={value.description}
              />
              {error.description && touched.description && (
                <p className="error">{error.description}</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BlogModal;
