import { useMutation, useQuery, useQueryClient } from "react-query";
import { mutatePutFn, QueryReturnType } from "../services/networking.service";
import ENDPOINTS from "../services/endPoints";
import { Auth } from "../../utils/interface";


interface IProps {
    content: string,
    type:string
}

export const useGetContents = (): QueryReturnType<any> => {
    return useQuery<never, never, any>(ENDPOINTS.GET_CONTENTS,{
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
};

export const useEditContent = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IProps>(data => mutatePutFn(ENDPOINTS.EDIT_CONTENT, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}