/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Wrapper from "../../../components/header/headerWrapper";
import RoutePath from "../../../routes/routesPath";
import { IBlogProps, ModalState } from "../../../utils/interface";
import { dateFormatter, ThumbnailGenerator } from "../../../utils/helpers";
import CustomTable from "../../../components/common/table";
import { blogsHeader } from "../../../utils/seeds";
import BlogsListing from "../../../components/screens/blog/blogsListing";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import BlogModal from "../../../components/screens/blog/blogModal";
import Modal from "../../../components/common/modal";
import CustomAlert from "../../../components/common/alert";
import Swal from "sweetalert2";
import {
  useAddBlog,
  useBlogDelete,
  useBlogStatus,
  useEditBlog,
  useGetBlogsList,
} from "../../../react-query/hooks/blog";
import { useNavigate } from "react-router-dom";
import { toast } from "react-custom-alert";

const BlogsManagement: React.FC = () => {
  const nav = useNavigate();
  // State variables for managing data pagination and filtering
  const [countData, setCountData] = React.useState<any>(10);
  const [pageNumber, setPageNumber] = React.useState<any>(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [sortValue, setSortValue] = React.useState<any>({
    from: "",
    to: "",
  });
  const [imageLink, setImageLink] = React.useState<any>(null);
  const [selectedImageLink, setSelectedImageLink] = React.useState<any>(null);
  const [selectedVideoLink, setSelectedVideoLink] = React.useState<any>(null);
  const [videoLink, setVideoLink] = React.useState<{
    file: any;
    thumbnail: any;
  }>({ file: null, thumbnail: null });

  const [modalState, setModalState] = React.useState<ModalState>({
    open: false,
    modalType: "",
    modalTitle: "",
    data: {},
  });
  // Hook to fetch blogs links data
  const {
    mutateAsync: getBlogsListMutateAsync,
    data,
    isLoading,
  } = useGetBlogsList();
  const fetchBlogsList = async () => {
    try {
      await getBlogsListMutateAsync({
        limit: countData,
        page: pageNumber,
        startDate: "",
        endDate: "",
        search: "",
      });
    } catch (error) {
      console.error("Error fetching Blogs list:", error);
    }
  };
  React.useEffect(() => {
    fetchBlogsList();
  }, [countData, pageNumber]);

  // Mutation hooks for handling dontion add,edit,status and deletion
  const { mutateAsync: blogAddMutatAsync, isLoading: addBlogLoading } =
    useAddBlog();
  const { mutateAsync: blogEditMutatAsync, isLoading: editBlogLoading } =
    useEditBlog();
  const {
    mutateAsync: blogStatusUpdateMutatAsync,
    isSuccess,
    data: statusData,
  } = useBlogStatus();
  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success(statusData?.message);
    }
  }, [isSuccess]);
  const { mutateAsync: blogDeleteMutatAsync } = useBlogDelete();

  // Handle previous page navigation
  function handlePrevPage() {
    setPageNumber((prevPage: any) => prevPage - 1);
  }

  // Handle next page navigation
  function handleNextPage() {
    setPageNumber((nextPage: any) => nextPage + 1);
  }

  // Handle row limit change
  function handleRowLimitChange(newValue: number) {
    if (pageNumber === Math.ceil(data?.data?.totalRecords / countData)) {
      setPageNumber(1);
    }
    setCountData(newValue);
  }

  // Handle search input change
  async function handleSearch() {
    await getBlogsListMutateAsync({
      limit: countData,
      page: pageNumber,
      startDate:
        sortValue.from && sortValue.to ? dateFormatter(sortValue.from) : "",
      endDate:
        sortValue.to && sortValue.from ? dateFormatter(sortValue.to) : "",
      search: searchValue,
    });
  }

  // Handle clear filter values
  async function handleResetValues() {
    setSearchValue("");
    setSortValue({
      from: "",
      to: "",
    });
    fetchBlogsList();
  }

  // Function to handle file change
  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    const fileType = file?.type.split("/")[0];
    if (fileType === "video") {
      ThumbnailGenerator(file, (thumbnailFile: any) => {
        setVideoLink({
          file: file,
          thumbnail: thumbnailFile,
        });
        setFieldValue("image", file);
        setImageLink(null);
      });
    } else {
      setImageLink(file);
      setVideoLink({
        file: null,
        thumbnail: null,
      });
      setFieldValue("image", file);
    }
  }
  // formik for handle validation for add or edit blog
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: INITIALVALUES.blogInitialValues,
    validationSchema: VALIDATIONS.blogValidationSchema,
    onSubmit: async () => {
      let formData = new FormData();
      formData.append("title", values.title);
      formData.append("description", values.description);
      if (imageLink && !videoLink.file) {
        formData.append("imageLink", imageLink);
        formData.append("videoLink", "null");
      } else if (!imageLink && videoLink.file) {
        formData.append("videoLink", videoLink.file);
        formData.append("imageLink", "null");
      } else {
        formData.append("imageLink", selectedImageLink);
        formData.append("videoLink", selectedVideoLink);
      }
      try {
        if (modalState.modalType === "Add") {
          let res: any = await blogAddMutatAsync({ data: formData });
          if (res?.status === 200) {
            toast.success(res?.message);
            setModalState({
              ...modalState,
              open: false,
            });
            handleCloseModal();
            fetchBlogsList();
          }
        } else if (modalState.modalType === "Edit") {
          formData.append("id", modalState.data?._id);
          let res: any = await blogEditMutatAsync({ data: formData });
          if (res?.status === 200) {
            toast.success(res?.message);
            setModalState({
              ...modalState,
              open: false,
            });
            handleCloseModal();
            fetchBlogsList();
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.status === false) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data || error?.message);
        }
      }
    },
  });

  // close modal with reset values
  function handleCloseModal() {
    setModalState({
      ...modalState,
      open: false,
      data: {},
    });
    resetForm();
    setVideoLink({
      file: null,
      thumbnail: null,
    });
    setImageLink(null);
  }

  // Handle modal open for add or edit
  function handleModalOpen(e: React.MouseEvent<HTMLButtonElement>, data?: any) {
    if (e.currentTarget.title === "Add") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.currentTarget.title,
        data: {},
        modalTitle: "Add Blog",
      });
    } else if (e.currentTarget.title === "Edit") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.currentTarget.title,
        data: data,
        modalTitle: "Edit Blog",
      });
    } else {
      return null;
    }
  }

  // getting data when edit blog
  React.useEffect(() => {
    setValues({
      title: modalState.data?.title || "",
      description: modalState.data?.description || "",
      image: modalState.data?.imageLink || modalState.data?.videoLink || null,
    });
    setSelectedImageLink(modalState.data?.imageLink);
    setSelectedVideoLink(modalState.data?.videoLink);
  }, [modalState.data]);

  // handle function for show modal
  function returnModalChild() {
    if (modalState.modalType === "Add" || modalState.modalType === "Edit") {
      return (
        <Modal
          loading={
            modalState.modalType === "Add" ? addBlogLoading : editBlogLoading
          }
          open={modalState.open}
          closeModalFn={handleCloseModal}
          saveFn={() => handleSubmit()}
          title={modalState.modalTitle}
        >
          <BlogModal
            selectedImageLink={selectedImageLink}
            selectedVideoLink={selectedVideoLink}
            imageLink={imageLink}
            videoLink={videoLink.thumbnail}
            handleImageChange={handleImageChange}
            error={errors}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched}
            value={values}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  // Toggle active/inactive for product status change
  async function handleToggleStaus(item: IBlogProps) {
    try {
      let res: any = await blogStatusUpdateMutatAsync({
        id: item?._id,
        status: item?.status === "0" ? "1" : "0",
      });
      if (res?.status === 200) {
        fetchBlogsList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Handle delete blog confirmation
  async function handleDeleteBlog(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the blog?",
      confirmButtonText: "Delete",
      icon: "warning",
      onConfirm: async () => {
        Swal.fire({
          icon: "success",
          text: "Done",
          timer: 1000,
          showConfirmButton: false,
        });
        try {
          let res: any = await blogDeleteMutatAsync({
            _id: id,
          });
          if (res?.status === 200) {
            Swal.fire({
              title: "Deleted successfully",
              showConfirmButton: false,
              icon: "success",
              timer: 1200,
            });
            fetchBlogsList();
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: "An error occurred while deleting the blog",
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        }
      },
    });
  }

  // navigate to detail screen with particular blog data
  function onClickDetail(items: IBlogProps) {
    nav(RoutePath.BlogDetail.path, { state: items?._id });
  }

  return (
    <Wrapper title={RoutePath.BlogsManagement.title}>
      <CustomTable
        handleAddBtn={(e) => handleModalOpen(e)}
        handleReset={handleResetValues}
        response={data?.data?.blog?.length === 0}
        notFoundTitle={"Blogs not found!"}
        sortValue={sortValue}
        setSortValue={setSortValue}
        loading={isLoading}
        currentPage={pageNumber}
        setPageNumber={setPageNumber}
        countData={countData}
        totalRecords={data?.data?.totalRecords}
        handleRowLimitChange={handleRowLimitChange}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handleSearch={handleSearch}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        title="Blogs Listing"
        tableHeader={blogsHeader}
        btnTitle="Add New Blog"
        isBtnRequired={true}
      >
        <BlogsListing
          handleToggleStaus={handleToggleStaus}
          data={data?.data?.blog}
          onClickDetail={onClickDetail}
          handleDeleteBlog={handleDeleteBlog}
          handleModalOpen={handleModalOpen}
        />
      </CustomTable>
      {returnModalChild()}
    </Wrapper>
  );
};
export default BlogsManagement;
