export const Strings = {
    welcome_to_gluco: 'Welcome to Gluco',
    first_name: 'First Name',
    last_name: 'Last Name',
    email: 'Email',
    mobile_number: 'Mobile Number',
    gender: 'Gender',
    dob: 'DOB',
    status: 'Status',
    answer_some_questions: "Answers some questions :",
    password: "Password",
    save: "Save",
    cancel: "Cancel",
    edit: "Edit",
    change_password: "Change Password",
    product_name: "Product Name",
    product_price: "Product Price",
    product_link: "Product Link",
    organization_title: "Organization Title*",
    organization_link: "Organization Link*",
    blog_title: "Blog Title*",
    blog_description: "Blog Description*",
}