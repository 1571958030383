// import Swal from 'sweetalert2';

import Swal from "sweetalert2";

interface IProps {
    title: string;
    icon: 'warning' | 'error' | 'success' | 'info' | 'question';
    onConfirm: () => void;
    confirmButtonText: string;
    html?: any
}

const CustomAlert = ({
    icon,
    confirmButtonText,
    onConfirm,
    title
}: IProps) => {
    return (
        Swal.fire({
            title: title,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: "Cancel",
            confirmButtonColor: "#ff0000a3",
            cancelButtonColor: "#0a2545",
            icon: icon,
        }
        ).then((result) => {
            if (result.isConfirmed) {
                onConfirm()
            }
        })
    )
};
export default CustomAlert