import React from "react"
import IMAGES from "../../../utils/constants/images"
import { Strings } from "../../../utils/constants/strings";
import ModalInputs from "../../common/modalInputs";

type IProps = {
    onChange: any
    onBlur: any
    error: any
    touched: any
    value: any
    handleImageChange: (e: any) => void
    donationLogo: File
}

const OrganizationModal: React.FC<IProps> = ({ onChange, onBlur, error, touched, value, handleImageChange, donationLogo }) => {
    const fileInputRef = React.useRef<any>(null);
    const handleSelectFromLibrary = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    return (
        <div className="modal-flex-blk">
            <div className="modal-img-blk">
                {(donationLogo || value?.image)
                    ?
                    <img
                        width={"100%"}
                        height={"100%"}
                        src={donationLogo ? URL.createObjectURL(donationLogo) : value?.image}
                        alt='' />
                    : <img src={IMAGES.LOGO.organizationLogo} alt="Img" />}
                <div className="modal-upld-file">
                    <input onChange={handleImageChange} type="file" name="" ref={fileInputRef} className="d-none" />
                    <label onClick={handleSelectFromLibrary}>
                        <span className="upld-texticon">Click here to upload</span>
                    </label>
                </div>
                {error.image && touched.image && <p className="error mt-4">{error.image}</p>}
            </div>
            <div className="modal-form-blk">
                <div className="row gy-4">
                    <ModalInputs
                        name="title"
                        onBlur={onBlur}
                        onChange={onChange}
                        error={error.title}
                        touched={touched.title}
                        title={Strings.organization_title}
                        value={value.title}
                        placeholder="Enter Organization Title"
                    />
                    <ModalInputs
                        name="link"
                        onBlur={onBlur}
                        onChange={onChange}
                        error={error.link}
                        touched={touched.link}
                        title={Strings.organization_link}
                        value={value.link}
                        placeholder="Enter Organization Link"
                    />
                </div>
            </div>
        </div>
    )
};
export default OrganizationModal