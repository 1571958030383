import React from "react";
import { Strings } from "../../../utils/constants/strings";
import { IUserDetailsQuestionsProps } from "../../../utils/interface";

type Props = {
  getUserDetail: any;
};

const DiabetesQuestionsInfo = ({ getUserDetail }: Props) => {
  return (
    <div
      className="col col-sm-12"
      style={{ maxHeight: "400px", overflowY: "auto" }}
    >
      <div className="cutomer-detial-questions-blk">
        <span className="customer-info-title">
          {Strings.answer_some_questions}
        </span>
      </div>
      <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-1 g-5">
        {getUserDetail?.answers?.map(
          (items: IUserDetailsQuestionsProps, index: number) => (
            <div key={index} className="col">
              <div className="question-selection-blk">
                <h3>{items?.question}</h3>
                <div className="question-selection-option">
                  <div className="selection-option-field">
                    <input
                      type="radio"
                      className="d-none"
                      name={`question_${index}`}
                      checked={items?._id ? true : false}
                    />
                    <label>
                      <span className="radio-circle"></span>
                      <span className="radio-text">{items?.answer}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};
export default DiabetesQuestionsInfo;
