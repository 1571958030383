import IMAGES from '../../utils/constants/images'

const InProgress = () => {
    return (
        <div className='d-flex justify-content-center align-items-center vh-60'>
            <img src={IMAGES.BACKGOURND.onProgress} alt='' />
        </div>
    )
}

export default InProgress