import React from "react";

type Props = {
  title: string;
  type: string;
  value: string;
  readOnly: boolean;
  onChange?: any;
  onBlur?: any;
  error?: any;
  touched?: any;
  name?: string;
  showBlink?: boolean;
};

const EditInfo = ({
  title,
  type,
  value,
  readOnly,
  error,
  onBlur,
  onChange,
  touched,
  name,
  showBlink,
}: Props) => {
  return (
    <div className="col-sm-6">
      <div className={`common-input ${showBlink ? "blink" : ""}`}>
        <label>{title}</label>
        <div className="input-field">
          <input
            autoComplete="off"
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            type={type}
            className="form-control"
            value={value}
            readOnly={readOnly}
          />
        </div>
      </div>
      {error && touched && <p className="error">{error}</p>}
    </div>
  );
};
export default EditInfo;
