import * as Yup from 'yup';

const initialValuesEditProfile = {
    username: "",
};

const editProfileSchema = Yup.object({
    username: Yup.string().required("User name is required*"),
});

export {
    initialValuesEditProfile,
    editProfileSchema
}