import * as Yup from 'yup';

// initialValues
const productInitialValues = {
    productName: "",
    price: "",
    productLink: "",
    productImage: null,
}

// validation schema
const productValidationSchema = Yup.object().shape({
    productName: Yup.string().required("Please enter your product name*"),
    price: Yup.string().required("Please enter your product price*"),
    productLink: Yup.string().required("Please enter your product link*"),
    productImage: Yup.string().required("Image is required*"),
});

export {
    productInitialValues,
    productValidationSchema,
}