import { useMutation, useQuery, useQueryClient } from "react-query";
import ENDPOINTS from "../services/endPoints";
import { Auth, IProps } from "../../utils/interface";
import {
  mutateGetFn,
  mutatePostFn,
  mutatePutFn,
  QueryReturnType,
} from "../services/networking.service";

export const useGetUsersList = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutateGetFn(ENDPOINTS.USERS_LIST, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const useGetUserDetail = ({ id }: IProps): QueryReturnType<any> => {
  return useQuery<never, never, any>([ENDPOINTS.USER_DETAIL, { id: id }], {
    notifyOnChangeProps: [
      "data",
      "error",
      "refetch",
      "isRefetching",
      "isSuccess",
      "isLoading",
    ],
  });
};

export const useUserStatus = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePutFn(ENDPOINTS.USER_STATUS, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};

export const useUserDelete = () => {
  const queryClient = useQueryClient();
  return useMutation<Auth, never, any>(
    (data) => mutatePostFn(ENDPOINTS.REVERT_USER_DELETE, data),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries([]);
      },
    }
  );
};
