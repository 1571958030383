/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { ITableProps, KeyValueInterface } from "../../utils/interface";
import { PulseLoader } from "react-spinners";
import { AddICon } from "./svg";

const CustomTable = ({
  children,
  title,
  tableHeader,
  btnTitle,
  isBtnRequired,
  handleRowLimitChange,
  handleNextPage,
  handlePrevPage,
  setPageNumber,
  currentPage,
  countData,
  totalRecords,
  loading,
  setSortValue,
  sortValue,
  searchValue,
  setSearchValue,
  handleSearch,
  response,
  notFoundTitle,
  handleReset,
  handleAddBtn,
}: ITableProps) => {
  const [rowLimit, setRowLimit] = React.useState(10);
  function handleSortValue(date: Date, name: string) {
    setSortValue({ ...sortValue, [name]: date });
  }

  function handleSearchChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setSearchValue(value);
  }

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
    setRowLimit(Number(event.target.value));
    handleRowLimitChange(Number(event.target.value));
  }

  return (
    <div className="container-xxl">
      <div className="card">
        <div className="card-wrapper">
          {loading ? (
            <div className="d-flex justify-content-center align-items-center vh-60">
              <PulseLoader size={18} color="#012746" />
            </div>
          ) : (
            <>
              <div className="d-flex   justify-content-end justify-content-lg-between flex-wrap z-index-3 w-100 gap-3">
                <div className="d-flex gap-3 w-100 w-lg-50 flex-wrap">
                  <div
                    className="d-flex align-items-center position-relative"
                    style={{ maxWidth: "350px", width: "100%" }}
                  >
                    <span className="svg-icon svg-icon-1 position-absolute ms-6">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <rect
                          opacity="0.5"
                          x="17.0365"
                          y="15.1223"
                          width="8.15546"
                          height={2}
                          rx={1}
                          transform="rotate(45 17.0365 15.1223)"
                          fill="currentColor"
                        />
                        <path
                          d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <input
                      className="form-control form-control-solid ps-14"
                      placeholder="Search..."
                      onKeyPress={(e) => {
                        if (e.key === "Enter" && handleSearch) {
                          handleSearch();
                        }
                      }}
                      value={searchValue}
                      onChange={handleSearchChange}
                    />
                  </div>
                  <div className="filter-fields">
                    <div className="filter-action-blk">
                      <button className="apply-btn" onClick={handleSearch}>
                        Apply
                      </button>
                      <button
                        className="clear-btn"
                        disabled={searchValue ? false : true}
                        onClick={handleReset}
                      >
                        Clear
                      </button>
                    </div>
                  </div>
                </div>

                {isBtnRequired && (
                  <div className="side-content-blk">
                    <button
                      title="Add"
                      className="primary-btn"
                      onClick={handleAddBtn}
                    >
                      <AddICon />
                      {btnTitle}
                    </button>
                  </div>
                )}
              </div>
              {response ? (
                <div className="w-100 justify-content-center align-items-center d-flex vh-40">
                  <p className="m-0 fs-4">{notFoundTitle}</p>
                </div>
              ) : (
                <div className="card-body p-0">
                  <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                    <div className="table-responsive">
                      <table className="table align-middle  fs-6 gy-5 dataTable no-footer">
                        <thead>
                          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            {tableHeader.map(
                              ({ value, key }: KeyValueInterface) => {
                                return <th key={value}>{value}</th>;
                              }
                            )}
                          </tr>
                        </thead>
                        <tbody className="text-gray-600 fw-bold">
                          {children}
                        </tbody>
                      </table>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start" />
                      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                        <div className="dataTables_paginate paging_simple_numbers">
                          <ul className="pagination align-items-center">
                            <p className="m-0 fs-5 pe-4">Rows per page :</p>
                            <div className="filter-by-sort">
                              <select
                                value={rowLimit}
                                onChange={handleChange}
                                className="form-select rows-select"
                              >
                                <option value={10}>10</option>
                                <option value={15}>15</option>
                                <option value={25}>25</option>
                              </select>
                            </div>
                            <li
                              className={`paginate_button page-item previous ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                            >
                              <a className="page-link" onClick={handlePrevPage}>
                                <i className="previous" />
                              </a>
                            </li>
                            {Array.from({
                              length: Math.ceil(totalRecords / countData),
                            }).map((_, index) => (
                              <li
                                key={index + 1}
                                className={`paginate_button page-item ${
                                  currentPage === index + 1 ? "active" : ""
                                }`}
                              >
                                <a
                                  className="page-link"
                                  onClick={() => setPageNumber(index + 1)}
                                >
                                  {index + 1}
                                </a>
                              </li>
                            ))}
                            <li
                              className={`paginate_button page-item next ${
                                currentPage ===
                                Math.ceil(totalRecords / countData)
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <a className="page-link" onClick={handleNextPage}>
                                <i className="next" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default CustomTable;
