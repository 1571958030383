import { useMutation, useQuery, useQueryClient } from "react-query";
import ENDPOINTS from "../services/endPoints";
import { Auth } from "../../utils/interface";
import { mutateDeleteFn, mutateGetFn, mutatePostFn, mutatePutFn, QueryReturnType } from "../services/networking.service";

interface IProps {
    data: FormData
}

export const useAddProduct = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IProps>(data => mutatePostFn(ENDPOINTS.ADD_PRODUCT, data.data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useEditProduct = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IProps>(data => mutatePutFn(ENDPOINTS.EDIT_PRODUCT, data.data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useGetProductsList = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>((data) => mutateGetFn(ENDPOINTS.PRODUCT_LIST, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
};

export const useGetProductDetail = ({ _id }: any): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.PRODUCT_DETAIL, { _id: _id }],
        {
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
};

export const useProductStatus = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>(data => mutatePutFn(ENDPOINTS.PRODUCT_STATUS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
};

export const useProductDelete = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>(data => mutateDeleteFn(ENDPOINTS.PRODUCT_DELETE, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
};