import React from "react";
import Wrapper from "../../../components/header/headerWrapper";
import RoutePath from "../../../routes/routesPath";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetBlogDetail } from "../../../react-query/hooks/blog";
import IMAGES from "../../../utils/constants/images";
import { IBlogProps } from "../../../utils/interface";

const BlogDetail: React.FC = () => {
  // Navigation function
  const nav = useNavigate();

  // This reference will be used to access the video DOM element directly.
  const videoRef = React.useRef<any>(null);

  // Get current location
  const location = useLocation();

  // Fetch blog details using custom hook
  const { data } = useGetBlogDetail({
    id: location?.state,
  });

  // Extracting blog detail blog
  const getBlogDetail: IBlogProps = data?.data;

  return (
    <Wrapper title={RoutePath.BlogsManagement.title}>
      <div className="container-xxl">
        <div className="card">
          <div className="card-wrapper pb-10">
            <div className="heading-flex-box">
              <div className="sub-heading common-sub-heading d-flex align-items-center gap-3">
                <img
                  width={"20"}
                  className="cursor-pointer"
                  src={IMAGES.ICONS.backIcon}
                  alt=""
                  onClick={() => nav(-1)}
                />
                <h2>{RoutePath.BlogDetail.title}</h2>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-2 row-12 g-5">
              <div className="col">
                <div className="custmorDetail-img-blk">
                  {getBlogDetail?.imageLink ? (
                    <img src={getBlogDetail?.imageLink} alt="profile" />
                  ) : (
                    <video
                      ref={videoRef}
                      src={getBlogDetail?.videoLink}
                      style={{ width: "100%", height: "100%" }}
                    />
                  )}
                </div>
              </div>
              <div className="col p-14">
                <h1>{getBlogDetail?.title}</h1>
                <p className="mt-5 fs-5">{getBlogDetail?.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default BlogDetail;
