import {
  HashRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { useAppContext } from "../context/authContext";
import RoutePath from "./routesPath";
import PageNotFound from "../components/pageNotFound";
import Header from "../components/header";

const Navigation = () => {
  const { isAuthenticated } = useAppContext();

  const PublicRoute = () => {
    if (isAuthenticated) {
      return <Navigate to={RoutePath.UsersManagement.path} replace />;
    }
    return <Outlet />;
  };

  const ProtectedRoute = () => {
    if (!isAuthenticated) {
      return <Navigate to={"/"} replace />;
    }
    return (
      <>
        <Header />
        <Outlet />
      </>
    );
  };

  return (
    <Router>
      <Routes>
        <Route element={<PublicRoute />}>
          <Route
            path={RoutePath.Landing.path}
            element={RoutePath.Landing.component}
          />
          <Route
            path={RoutePath.Login.path}
            element={RoutePath.Login.component}
          />
          <Route
            path={RoutePath.DeleteAccount.path}
            element={RoutePath.DeleteAccount.component}
          />
        </Route>
        <Route element={<ProtectedRoute />}>
          <Route
            path={RoutePath.UsersManagement.path}
            element={RoutePath.UsersManagement.component}
          />
          <Route
            path={RoutePath.UsersDetail.path}
            element={RoutePath.UsersDetail.component}
          />
          <Route
            path={RoutePath.ProductManagement.path}
            element={RoutePath.ProductManagement.component}
          />
          <Route
            path={RoutePath.ProductDetail.path}
            element={RoutePath.ProductDetail.component}
          />
          <Route
            path={RoutePath.DonationLinks.path}
            element={RoutePath.DonationLinks.component}
          />
          <Route
            path={RoutePath.BlogsManagement.path}
            element={RoutePath.BlogsManagement.component}
          />
          <Route
            path={RoutePath.BlogDetail.path}
            element={RoutePath.BlogDetail.component}
          />
          <Route
            path={RoutePath.ContentManagement.path}
            element={RoutePath.ContentManagement.component}
          />
          <Route
            path={RoutePath.TermsAndCondition.path}
            element={RoutePath.TermsAndCondition.component}
          />
          <Route
            path={RoutePath.PrivacyAndPolicy.path}
            element={RoutePath.PrivacyAndPolicy.component}
          />
          <Route
            path={RoutePath.About.path}
            element={RoutePath.About.component}
          />
          <Route
            path={RoutePath.Profile.path}
            element={RoutePath.Profile.component}
          />
          <Route
            path={RoutePath.Notifications.path}
            element={RoutePath.Notifications.component}
          />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};
export default Navigation;
