import React, { useState } from 'react'
import { Strings } from '../../utils/constants/strings'
import ChangePasswordInfo from '../../components/screens/profile/changePasswordInfo'
import { useFormik } from 'formik'
import { INITIALVALUES, VALIDATIONS } from '../../utils/validation'
import { useChangePassword } from '../../react-query/hooks/profile'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-custom-alert'

type Props = {
    id: string
    showChangePasswordBlk: boolean
    setShowChangePasswordBlk: any
}

const ChangePassword = ({ id, showChangePasswordBlk, setShowChangePasswordBlk }: Props) => {

    // State variable to manage password visibility
    const [passwordVisibility, setPasswordVisibility] = useState({
        OldPassword: false,
        NewPassword: false,
        ConfirmPassword: false
    });

    // Hook to change password of admin user 
    const { mutateAsync, isLoading } = useChangePassword()

    // Function to handle password visibility
    const handlePasswordVisibility = (value: string) => {
        setPasswordVisibility((prevState: any) => ({
            ...prevState,
            [value]: !prevState[value]
        }));
    };

    // handle api with valiadtions using formik
    const { values, errors, touched, handleBlur, handleChange, handleSubmit, resetForm } = useFormik({
        initialValues: INITIALVALUES.initialValuesChangePassword,
        validationSchema: VALIDATIONS.changePasswordSchema,
        onSubmit: async () => {
            try {
                let res = await mutateAsync({
                    _id: id,
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword
                });
                toast.success(res.message);
                resetForm();
                setShowChangePasswordBlk(false)
                setPasswordVisibility({
                    OldPassword: false,
                    NewPassword: false,
                    ConfirmPassword: false
                })
            } catch (error: any) {
                if (error?.response?.status === 400) {
                    toast.error(error?.response?.data?.message)
                }
                else {
                    toast.error(error?.response?.data || error?.message)
                }
            }
        }
    });

    // Function to handle cancellation of password change
    function onCancel() {
        setShowChangePasswordBlk(false)
        resetForm()
        setPasswordVisibility({
            OldPassword: false,
            NewPassword: false,
            ConfirmPassword: false
        })
    }

    return (
        <div className={`change-password-outer ${showChangePasswordBlk ? "d-block" : "d-none"}`}>
            <div className="row">
                <div className="col-12">
                    <div className="change-input-fields">
                        <div className="changepassword-heading">
                            <h2>{Strings.change_password}</h2>
                        </div>
                        <div className="row gy-4">
                            <div className="row">
                                <ChangePasswordInfo
                                    name='oldPassword'
                                    placeholder='Enter old password'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.oldPassword}
                                    touched={touched.oldPassword}
                                    title={`Old ${Strings.password}`}
                                    value={values.oldPassword}
                                    showPassword={passwordVisibility.OldPassword}
                                    handlePasswordVisibility={() => handlePasswordVisibility('OldPassword')}
                                />
                            </div>
                            <div className="row">
                                <ChangePasswordInfo
                                    name='newPassword'
                                    placeholder='Enter new password'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.newPassword}
                                    touched={touched.newPassword}
                                    title={`New ${Strings.password}`}
                                    value={values.newPassword}
                                    showPassword={passwordVisibility.NewPassword}
                                    handlePasswordVisibility={() => handlePasswordVisibility('NewPassword')}
                                />
                                <ChangePasswordInfo
                                    name='confirmPassword'
                                    placeholder='Enter confirm password'
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={errors.confirmPassword}
                                    touched={touched.confirmPassword}
                                    title={`Confirm ${Strings.password}`}
                                    value={values.confirmPassword}
                                    showPassword={passwordVisibility.ConfirmPassword}
                                    handlePasswordVisibility={() => handlePasswordVisibility('ConfirmPassword')}
                                />

                            </div>
                            <div className="col-sm-6 align-self-end w-100">
                                <div className="btn-input stack gap-3 ">
                                    <button className='cancelbtn'
                                        onClick={onCancel}>
                                        {Strings.cancel}
                                    </button>
                                    <button
                                        disabled={isLoading}
                                        className='savebtn'
                                        onClick={() => handleSubmit()}>
                                        {isLoading ? <ClipLoader size={15} color="#fff" /> : Strings.save}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default ChangePassword