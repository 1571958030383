import React from 'react'

type Props = {
    title: string
    value: string
    placeholder: string
    name: string
    onChange: any
    onBlur: any
    error: any
    touched: any
    showPassword: boolean
    handlePasswordVisibility: () => void
}

const ChangePasswordInfo = ({ title, value, showPassword, handlePasswordVisibility, onBlur, onChange, error, touched, placeholder, name }: Props) => {
    return (
        <div className={"col-sm-6"}>
            <div className="common-input">
                <label >{title}</label>
                <div className="input-field mb-5">
                    <input
                        name={name}
                        type={showPassword ? 'text' : 'password'}
                        className="form-control"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        placeholder={placeholder}
                    />
                    <span className="eye" onClick={handlePasswordVisibility}>
                        {showPassword ? (
                            <i className="bi bi-eye-fill"></i>
                        ) : (
                            <i className="bi bi-eye-slash-fill"></i>
                        )}
                    </span>
                    {error && touched && <p className='error'>{error}</p>}
                </div>
            </div>
        </div>
    )
}
export default ChangePasswordInfo;