import AboutUs from "./AboutUs";
import Login from "./auth/login";
import BlogDetail from "./blog/blogDetail";
import BlogsManagement from "./blog/blogsManagement";
import ContentManagement from "./contentManagement";
import DeleteAccount from "./deleteAccount";
import DonationLinksListing from "./donation/donationLinksListing";
import Notifications from "./notification";
import PrivacyPolicy from "./privacyPolicy";
import ProductDetail from "./product/productDetail";
import ProductManagement from "./product/productManagement";
import Profile from "./profile";
import TermsAndConditons from "./termsAndConditions";
import UserDetail from "./user/userDetail";
import UsersManagement from "./user/usersManagement";

const adminScreens = {
    Login: Login,
    DeleteAccount: DeleteAccount,
    UsersManagement: UsersManagement,
    UserDetail: UserDetail,
    ProductManagement: ProductManagement,
    ProductDetail: ProductDetail,
    DonationLinksListing: DonationLinksListing,
    BlogsManagement: BlogsManagement,
    BlogDetail: BlogDetail,
    ContentManagement: ContentManagement,
    Profile: Profile,
    Notifications: Notifications,
    TermsAndConditons: TermsAndConditons,
    PrivacyPolicy: PrivacyPolicy,
    AboutUs: AboutUs
}
export default adminScreens;