import { useMutation, useQueryClient } from "react-query";
import ENDPOINTS from "../services/endPoints";
import { Auth } from "../../utils/interface";
import { mutateDeleteFn, mutateGetFn, mutatePostFn, mutatePutFn } from "../services/networking.service";

interface IProps {
    data: FormData
}

export const useAddDonation = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IProps>(data => mutatePostFn(ENDPOINTS.ADD_DONATION, data.data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useEditDonation = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IProps>(data => mutatePutFn(ENDPOINTS.EDIT_DONATION, data.data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useGetDonatoinsList = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>((data) => mutateGetFn(ENDPOINTS.DONATION_LIST, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
};

export const useDonationStatus = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>(data => mutatePutFn(ENDPOINTS.DONATION_STATUS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
};

export const useDonationDelete = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>(data => mutateDeleteFn(ENDPOINTS.DONATION_DELETE, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
};