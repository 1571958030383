import React, { createContext, useContext, useState } from 'react';
import userGlobalConfig from '../utils/constants/globalConfig';
import { toast } from 'react-custom-alert';

const AppContext = createContext<any>(null);

interface IProps {
    children: React.ReactNode
}

export const AuthProvider = ({ children }: IProps) => {
    const [isAuthenticated, setisAuthenticated] = useState(localStorage.getItem(userGlobalConfig.GLUCO_ADMIN_TOKEN) ?? false);
    const isLoggedOut = () => {
        setisAuthenticated(false);
        localStorage.clear();
        toast.success("Logout successfully")
    };

    return (
        <AppContext.Provider value={{ isAuthenticated, setisAuthenticated, isLoggedOut }}>
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => useContext(AppContext);