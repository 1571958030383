import * as Yup from 'yup';

// initialValues
const blogInitialValues = {
    title: "",
    description: "",
    image: null,
}

// validation schema
const blogValidationSchema = Yup.object().shape({
    title: Yup.string().required("Please enter your blog title*"),
    description: Yup.string().required("Please enter your blog description*"),
    image: Yup.string().required("Blog image/video is required*"),
});

export {
    blogInitialValues,
    blogValidationSchema,
}