import moment from "moment";
import { KeyboardEventHandler } from "react";

const NumberOnly: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (!/[0-9]/.test(event.key)) {
        event.preventDefault();
    }
};

const NumberOnlyWithDot: KeyboardEventHandler<HTMLInputElement> = (event) => {
    const { key, target } = event;
    const input = target as HTMLInputElement;
    const currentValue = input.value;
    // Regex to test valid input including decimal point
    const regex = /^\d*\.?\d{0,2}$/;
  
    // Test the new value if the key is pressed
    const newValue = currentValue + key;
    if (!regex.test(newValue)) {
      event.preventDefault();
    }
  };
  
  export default NumberOnlyWithDot;
  

function formatDate(dateString: any) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'long',
        year: 'numeric'
    };
    const formattedDate: string = date.toLocaleDateString('en-US', options);
    return formattedDate;
}

function formatDateWithTime(dateString: any) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    };
    const formattedDate: string = date.toLocaleString('en-US', options);
    return formattedDate;
}

function dateFormatter(fromattedDate: string) {
    const date = fromattedDate;
    const formattedDate = moment(date).format("YYYY-MM-DD");
    return formattedDate
};

function ThumbnailGenerator(file: any, setState: any) {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';
    videoElement.onloadedmetadata = async () => {
        const canvas = document.createElement('canvas');
        canvas.width = videoElement.videoWidth;
        canvas.height = videoElement.videoHeight;
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            console.error('Failed to get canvas context');
            return;
        }
        videoElement.currentTime = 0;
        await new Promise(resolve => {
            videoElement.onseeked = resolve;
        });
        ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
        canvas.toBlob(blob => {
            if (blob) {
                const thumbnailFile = new File([blob], 'thumbnail.png', { type: 'image/png' });
                setState(thumbnailFile);
            }
        },);
    };
    return videoElement.src = URL.createObjectURL(file);
}

function BlogImage({ url, children }: any) {
    const parts = url.split('.');
    const extension = parts.pop()?.toLowerCase();
    const supportedExtensions = ["png", "jpg", "jpeg", "gif", "webp"];
    if (extension && supportedExtensions.includes(extension)) {
        return (
            <img
                src={url}
                alt="Product"
                className="w-100"
            />
        );
    }
    else {
        return children
    }
};
export {
    NumberOnly,
    dateFormatter,
    formatDate,
    formatDateWithTime,
    ThumbnailGenerator,
    NumberOnlyWithDot,
    BlogImage
}