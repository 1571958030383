/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import RoutePath from "../../../routes/routesPath";
import { useNavigate } from "react-router-dom";
import { dateFormatter } from "../../../utils/helpers";
import CustomTable from "../../../components/common/table";
import Wrapper from "../../../components/header/headerWrapper";
import Swal from "sweetalert2";
import CustomAlert from "../../../components/common/alert";
import { IUserListProps } from "../../../utils/interface";
import UserListing from "../../../components/screens/user/userListing";
import {
  useGetUsersList,
  useUserDelete,
  useUserStatus,
} from "../../../react-query/hooks/user";
import { usersHeader } from "../../../utils/seeds";
import { toast } from "react-custom-alert";

const UsersManagement: React.FC = () => {
  // Navigation function for routing
  const nav = useNavigate();

  // State variables for managing data pagination and filtering
  const [countData, setCountData] = React.useState<any>(10);
  const [pageNumber, setPageNumber] = React.useState<any>(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [sortValue, setSortValue] = React.useState<any>({
    from: "",
    to: "",
  });

  // Hook to fetch user data
  const {
    mutateAsync: getUserListMutateAsync,
    data,
    isLoading,
  } = useGetUsersList();

  function fetData() {
    getUserListMutateAsync({
      limit: countData,
      page: pageNumber,
      startDate: "",
      endDate: "",
      search: "",
    });
  }
  React.useEffect(() => {
    fetData();
  }, [countData, pageNumber]);

  // Mutation hooks for handling user status and deletion
  const {
    mutateAsync: userStatusMutatAsync,
    isSuccess,
    data: statusData,
  } = useUserStatus();

  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success(statusData?.message);
    }
  }, [isSuccess]);
  const { mutateAsync: userDeleteMutatAsync } = useUserDelete();

  // Handle previous page navigation
  function handlePrevPage() {
    setPageNumber((prevPage: any) => prevPage - 1);
  }

  // Handle next page navigation
  function handleNextPage() {
    setPageNumber((nextPage: any) => nextPage + 1);
  }

  // Handle row limit change
  function handleRowLimitChange(newValue: number) {
    if (pageNumber === Math.ceil(data?.data?.totalRecords / countData)) {
      setPageNumber(1);
    }
    setCountData(newValue);
  }

  // Toggle active/inactive for user status change
  async function handleToggleStaus(item: any) {
    try {
      let res: any = await userStatusMutatAsync({
        _id: item?._id,
        status: item?.status === "0" ? "1" : "0",
      });
      if (res?.status === 200) {
        getUserListMutateAsync({
          limit: countData,
          page: pageNumber,
          startDate: "",
          endDate: "",
          search: "",
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Handle delete user confirmation
  async function handleDeleteUser(data: IUserListProps) {
    CustomAlert({
      title: data?.isDeleted
        ? "Are you sure want to revert the deleted user?"
        : "Are you sure want to delete the user?",
      confirmButtonText: data?.isDeleted ? "Revert" : "Delete",
      icon: "warning",
      onConfirm: async () => {
        try {
          let res: any = await userDeleteMutatAsync({
            _id: data?._id,
          });
          if (res?.status === 200) {
            Swal.fire({
              title: res?.message,
              showConfirmButton: false,
              icon: "success",
              timer: 1200,
            });
            getUserListMutateAsync({
              limit: countData,
              page: pageNumber,
              startDate: "",
              endDate: "",
              search: "",
            });
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: "An error occurred while deleting the user",
            icon: "error",
          });
        }
      },
    });
  }

  // Handle search input change
  async function handleSearch() {
    await getUserListMutateAsync({
      limit: countData,
      page: pageNumber,
      startDate:
        sortValue.from && sortValue.to ? dateFormatter(sortValue.from) : "",
      endDate:
        sortValue.to && sortValue.from ? dateFormatter(sortValue.to) : "",
      search: searchValue.trim(),
    });
  }

  // Handle clear filter values
  async function handleResetValues() {
    setSearchValue("");
    setSortValue({
      from: "",
      to: "",
    });
    getUserListMutateAsync({
      limit: countData,
      page: pageNumber,
      startDate: "",
      endDate: "",
      search: "",
    });
  }

  // navigate to detail screen with particular user data
  function onClickDetail(items: IUserListProps) {
    nav(RoutePath.UsersDetail.path, { state: items?._id });
  }

  return (
    <Wrapper title={RoutePath.UsersManagement.title}>
      <CustomTable
        handleReset={handleResetValues}
        response={data?.data?.users?.length === 0}
        notFoundTitle={"Users not found!"}
        sortValue={sortValue}
        setSortValue={setSortValue}
        loading={isLoading}
        currentPage={pageNumber}
        setPageNumber={setPageNumber}
        countData={countData}
        totalRecords={data?.data?.totalRecords}
        handleRowLimitChange={handleRowLimitChange}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handleSearch={handleSearch}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        title="Users Listing"
        tableHeader={usersHeader}
        btnTitle=""
        isBtnRequired={false}
      >
        <UserListing
          handleDeleteUser={handleDeleteUser}
          handleToggleStaus={handleToggleStaus}
          onClickDetail={onClickDetail}
          data={data?.data?.users}
        />
      </CustomTable>
    </Wrapper>
  );
};
export default UsersManagement;
