import React from "react";
import IMAGES from "../../../utils/constants/images";
import ModalInputs from "../../common/modalInputs";
import { Strings } from "../../../utils/constants/strings";
import { NumberOnly, NumberOnlyWithDot } from "../../../utils/helpers";

type IProps = {
  onChange: any;
  onBlur: any;
  error: any;
  touched: any;
  value: any;
  handleImageChange: (e: any) => void;
  productImage: File;
  setProductImage: any;
};

const ProductModal: React.FC<IProps> = ({
  onChange,
  onBlur,
  error,
  touched,
  value,
  handleImageChange,
  productImage,
}) => {
  const fileInputRef = React.useRef<any>(null);
  const handleSelectFromLibrary = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <div className="modal-flex-blk">
      <div className="modal-img-blk">
        {productImage || value?.productImage ? (
          <img
            width={"100%"}
            height={"100%"}
            src={
              productImage
                ? URL.createObjectURL(productImage)
                : value?.productImage
            }
            alt=""
          />
        ) : (
          <img src={IMAGES.LOGO.organizationLogo} alt="Img" />
        )}
        <div className="modal-upld-file">
          <input
            onChange={handleImageChange}
            type="file"
            name=""
            ref={fileInputRef}
            className="d-none"
          />
          <label onClick={handleSelectFromLibrary}>
            <span className="upld-texticon">Click here to upload</span>
          </label>
        </div>
        {error.productImage && touched.productImage && (
          <p className="error mt-4">{error.productImage}</p>
        )}
      </div>
      <div className="modal-form-blk">
        <div className="row gy-4">
          <ModalInputs
            name="productName"
            onBlur={onBlur}
            onChange={onChange}
            error={error.productName}
            touched={touched.productName}
            title={Strings.product_name + "*"}
            value={value.productName}
            placeholder="Enter Product Name"
          />
          <ModalInputs
            onKeyPress={NumberOnlyWithDot}
            name="price"
            onBlur={onBlur}
            onChange={onChange}
            error={error.price}
            touched={touched.price}
            title={Strings.product_price + "*"}
            value={value.price}
            placeholder="Enter Product Price"
          />
          <ModalInputs
            name="productLink"
            onBlur={onBlur}
            onChange={onChange}
            error={error.productLink}
            touched={touched.productLink}
            title={Strings.product_link + "*"}
            value={value.productLink}
            placeholder="Enter Product Link"
          />
        </div>
      </div>
    </div>
  );
};
export default ProductModal;
