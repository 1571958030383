import * as Yup from 'yup';

const initialValuesChangePassword = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
};

const changePasswordSchema = Yup.object({
    oldPassword: Yup.string().required("Old password is required*"),
    newPassword: Yup.string().min(5, "New password must be at least 5 characters").required("New password is required*"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Password must match")
        .required("Confirm password is required"),
});

export {
    initialValuesChangePassword,
    changePasswordSchema
}