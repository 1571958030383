import React from "react";
import { IContentsProps } from "../../utils/interface";

type Props = {
  data?: IContentsProps[];
};

const PrivacyPolicy: React.FC<Props> = ({ data }) => {
  // Filter out content with type === "1"
  const filteredData = data?.filter((item) => item.type === "1");

  //  render PrivacyAndPolicy with data
  function renderPrivacyAndPolicy() {
    return filteredData?.length ? (
      filteredData.map((item) => (
        <div key={item._id}>
          <p dangerouslySetInnerHTML={{ __html: item.content }} />
        </div>
      ))
    ) : (
      <p className="text-center m-10 fs-3">Privacy and policy no available!</p>
    );
  }
  return (
    <div className="container-xxl">
      <div className="card" style={{ maxHeight: "500px", overflowY: "auto" }}>
        <div className="card-wrapper">{renderPrivacyAndPolicy()}</div>
      </div>
    </div>
  );
};
export default PrivacyPolicy;
