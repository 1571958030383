/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import EditInfo from "../../components/screens/profile/editInfo";
import { Strings } from "../../utils/constants/strings";
import { IProfileProps } from "../../utils/interface";
import { INITIALVALUES, VALIDATIONS } from "../../utils/validation";
import { useEffect } from "react";
import { useEditProfile } from "../../react-query/hooks/profile";
import { toast } from "react-custom-alert";
import { ClipLoader } from "react-spinners";

type Props = {
  data: IProfileProps;
  showBtnBlk: boolean;
  setShowBtnBlk: any;
};

const EditProfile = ({ showBtnBlk, setShowBtnBlk, data }: Props) => {
  const { mutateAsync, isLoading } = useEditProfile();
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    resetForm,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: INITIALVALUES.initialValuesEditProfile,
    validationSchema: VALIDATIONS.editProfileSchema,
    onSubmit: async () => {
      try {
        let res: any = await mutateAsync({
          id: data?._id,
          username: values?.username || data?.username,
        });
        if (res?.status === 200) {
          toast.success(res?.message);
          handleCancel();
        }
        console.log(res);
      } catch (error: any) {
        if (error?.response?.data?.status === false) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data || error?.message);
        }
      }
    },
  });

  useEffect(() => {
    setFieldValue("username", data?.username);
  }, [data]);

  function handleCancel() {
    resetForm();
    setFieldValue("username", data?.username);
    setShowBtnBlk(false);
  }

  return (
    <div className="profile-input-fields">
      <div className="row gy-4">
        <EditInfo
          showBlink={showBtnBlk}
          error={errors.username}
          touched={touched.username}
          title="User Name"
          onBlur={handleBlur}
          onChange={handleChange}
          readOnly={showBtnBlk ? false : true}
          type="text"
          name="username"
          value={values.username}
        />
        <EditInfo
          title={Strings.email}
          readOnly={true}
          type="email"
          value={data?.email}
        />
        <div
          className={`col-sm-6 align-self-end w-100 ${
            showBtnBlk ? "d-block" : "d-none"
          }`}
        >
          <div className="btn-input stack gap-3 ">
            <button className="cancelbtn" onClick={handleCancel}>
              {Strings.cancel}
            </button>
            <button
              disabled={isLoading}
              className="savebtn"
              onClick={() => handleSubmit()}
            >
              {isLoading ? <ClipLoader size={15} color="#fff" /> : Strings.save}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditProfile;
