import React from "react";
import { contentHeader } from "../../../utils/seeds";
import { EditIcon } from "../../common/svg";
import { IContentsProps } from "../../../utils/interface";
import ContentEditor from "../../common/contentEditor";
import Modal from "../../common/modal";
import { useEditContent } from "../../../react-query/hooks/contents";
import { toast } from "react-custom-alert";

// Define props for ContentScreen component
type Props = {
  children: React.ReactNode;
  handleContentChange: (path: string) => void;
  isLinkActive: any;
  data: IContentsProps[];
  contentPath: string;
};

const ContentScreen: React.FC<Props> = ({
  children,
  handleContentChange,
  isLinkActive,
  data,
  contentPath,
}) => {
  // Custom hook from react-query for handling content editing
  const { mutateAsync: EditContentMutateAsync, isLoading } = useEditContent();

  // State for managing modal visibility and data
  const [modalState, setModalState] = React.useState({
    open: false,
    modalType: "",
    modalTitle: "",
    data: {},
  });
  // State for storing edited content value
  const [editValue, setEditValue] = React.useState("");

  // Filter data based on type for Terms & Conditions and Privacy & Policy
  const termsAndConditionsFilteredData = data?.filter(
    (item) => item.type === "0"
  );
  const privacyAndPolicyFilteredData = data?.filter(
    (item) => item.type === "1"
  );
  const aboutUsFilteredData = data?.filter((item) => item.type === "2");

  // Function to update editValue state with content to be edited
  function editContentChnage(value: any) {
    setEditValue(value);
  }

  // Function to close modal
  function handleCloseModal() {
    setModalState({
      ...modalState,
      open: false,
      data: {},
    });
    setEditValue("");
  }

  // Function to handle form submission
  async function handleSubmit() {
    try {
      if (modalState.modalType === "T&C") {
        let res: any = await EditContentMutateAsync({
          content: editValue || termsAndConditionsFilteredData[0]?.content,
          type: termsAndConditionsFilteredData[0]?.type,
        });
        if (res?.status === 200) {
          toast.success(res?.message);
          setModalState({
            ...modalState,
            open: false,
          });
          handleCloseModal();
        }
      } else if (modalState.modalType === "P&P") {
        let res: any = await EditContentMutateAsync({
          content: editValue || privacyAndPolicyFilteredData[0]?.content,
          type: privacyAndPolicyFilteredData[0]?.type,
        });
        if (res?.status === 200) {
          toast.success(res?.message);
          setModalState({
            ...modalState,
            open: false,
          });
          handleCloseModal();
        }
      } else if (modalState.modalType === "About") {
        let res: any = await EditContentMutateAsync({
          content: editValue || aboutUsFilteredData[0]?.content,
          type: aboutUsFilteredData[0]?.type,
        });
        if (res?.status === 200) {
          toast.success(res?.message);
          setModalState({
            ...modalState,
            open: false,
          });
          handleCloseModal();
        }
      }
    } catch (error: any) {
      if (error?.response?.data?.status === false) {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.response?.data || error?.message);
      }
    }
  }

  // Function to render modal based on modalType
  function renderModal() {
    if (
      modalState.modalType === "T&C" ||
      modalState.modalType === "P&P" ||
      modalState.modalType === "About"
    ) {
      return (
        <Modal
          loading={isLoading}
          open={modalState.open}
          closeModalFn={handleCloseModal}
          saveFn={handleSubmit}
          title={modalState.modalTitle}
        >
          <ContentEditor
            markup={modalState.data}
            onChange={editContentChnage}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  // Function to handle editing content based on contentPath
  function handleEditContent() {
    if (contentPath === "/termsandconditions") {
      setModalState({
        ...modalState,
        open: true,
        modalType: "T&C",
        data: termsAndConditionsFilteredData[0]?.content,
        modalTitle: "Edit Terms And Conditions",
      });
    } else if (contentPath === "/privacypolicy") {
      setModalState({
        ...modalState,
        open: true,
        modalType: "P&P",
        data: privacyAndPolicyFilteredData[0]?.content,
        modalTitle: "Edit Privacy Policy",
      });
    } else if (contentPath === "/aboutus") {
      setModalState({
        ...modalState,
        open: true,
        modalType: "About",
        data: aboutUsFilteredData[0]?.content,
        modalTitle: "Edit About Us",
      });
    } else return null;
  }

  return (
    <div className="container-xxl">
      <div className="card">
        <div className="card-wrapper pb-10">
          <div className="row">
            <div className="col-12">
              <div className="tablist-wrapper">
                <div className="tablist-blk">
                  <ul className="tablist">
                    {contentHeader.map((items, index) => {
                      return (
                        <li key={index}>
                          <button
                            onClick={() => handleContentChange(items.path)}
                            className={
                              isLinkActive(items.path)
                                ? "tab-buttons active"
                                : "tab-buttons"
                            }
                          >
                            {items.name}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div className="edit-icon-blk">
                  <button onClick={() => handleEditContent()}>
                    <EditIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>
      {renderModal()}
    </div>
  );
};
export default ContentScreen;
