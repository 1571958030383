/* eslint-disable no-mixed-operators */
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import RoutePath from "../../../routes/routesPath";
import Wrapper from "../../../components/header/headerWrapper";
import IMAGES from "../../../utils/constants/images";
import DetailInfo from "../../../components/screens/user/detailInfo";
import { Strings } from "../../../utils/constants/strings";
import DiabetesQuestionsInfo from "../../../components/screens/user/diabetesQuestionsInfo";
import { dateFormatter, formatDate } from "../../../utils/helpers";
import { useGetUserDetail } from "../../../react-query/hooks/user";
import { IUserListProps } from "../../../utils/interface";

const UserDetail: React.FC = () => {
  // Navigation function
  const nav = useNavigate();

  // Get current location
  const location = useLocation();

  // Fetch user details using custom hook
  const { data } = useGetUserDetail({
    id: location?.state,
  });

  // Extracting user detail data
  const getUserDetail: IUserListProps = data?.data;

  return (
    <Wrapper title={RoutePath.UsersManagement.title}>
      <div className="container-xxl">
        <div className="card">
          <div className="card-wrapper pb-10">
            <div className="heading-flex-box">
              <div className="sub-heading common-sub-heading d-flex align-items-center gap-3">
                <img
                  width={"20"}
                  className="cursor-pointer"
                  src={IMAGES.ICONS.backIcon}
                  alt=""
                  onClick={() => nav(-1)}
                />
                <h2>{RoutePath.UsersDetail.title}</h2>
              </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-5">
              <div className="col">
                <div className="custmorDetail-img-blk">
                  {getUserDetail?.profileImage ? (
                    <img src={getUserDetail?.profileImage} alt="profile" />
                  ) : (
                    <span>{getUserDetail?.firstName.charAt(0)}</span>
                  )}
                </div>
              </div>
              <div className="col">
                <div className="customer-detail-blk">
                  <DetailInfo
                    title={Strings.first_name}
                    info={getUserDetail?.firstName}
                  />
                  <DetailInfo
                    title={Strings.last_name}
                    info={getUserDetail?.lastName}
                  />
                  <DetailInfo
                    title={Strings.email}
                    info={getUserDetail?.email}
                  />
                  <DetailInfo
                    title={Strings.mobile_number}
                    info={
                      getUserDetail?.countryCode && getUserDetail?.mobileNumber
                        ? `${getUserDetail.countryCode} ${getUserDetail.mobileNumber}`
                        : "N/A"
                    }
                  />
                  <DetailInfo
                    title={Strings.gender}
                    info={
                      getUserDetail?.gender === "0"
                        ? "Other"
                        : getUserDetail?.gender === "1"
                        ? "Male"
                        : (getUserDetail?.gender === "2" && "Female") || "N/A"
                    }
                  />
                  <DetailInfo
                    title={Strings.dob}
                    info={
                      getUserDetail?.age
                        ? formatDate(getUserDetail?.age)
                        : "N/A"
                    }
                  />
                  <DetailInfo
                    title={Strings.status}
                    info={
                      getUserDetail?.status === "1" ? "Active" : "Suspended"
                    }
                  />
                  {getUserDetail?.isDeleted && (
                    <p className="deleted-text">
                      User deleted at {dateFormatter(getUserDetail?.deletedAt)}
                    </p>
                  )}
                </div>
              </div>
              <DiabetesQuestionsInfo getUserDetail={getUserDetail} />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default UserDetail;
