/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Wrapper from "../../../components/header/headerWrapper";
import RoutePath from "../../../routes/routesPath";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../../components/common/table";
import { productHeader } from "../../../utils/seeds";
import ProductListing from "../../../components/screens/product/productLisitng";
import CustomAlert from "../../../components/common/alert";
import Swal from "sweetalert2";
import {
  useAddProduct,
  useEditProduct,
  useGetProductsList,
  useProductDelete,
  useProductStatus,
} from "../../../react-query/hooks/product";
import { dateFormatter } from "../../../utils/helpers";
import { IProductListProps, ModalState } from "../../../utils/interface";
import Modal from "../../../components/common/modal";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import ProductModal from "../../../components/screens/product/productModal";
import { toast } from "react-custom-alert";

const ProductManagement: React.FC = () => {
  // Navigation function for routing
  const nav = useNavigate();

  // State variables for managing data pagination and filtering
  const [countData, setCountData] = React.useState<any>(10);
  const [pageNumber, setPageNumber] = React.useState<any>(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [sortValue, setSortValue] = React.useState<any>({
    from: "",
    to: "",
  });
  const [productImage, setProductImage] = React.useState<any>(null);
  const [modalState, setModalState] = React.useState<ModalState>({
    open: false,
    modalType: "",
    modalTitle: "",
    data: {},
  });

  // Hook to fetch product data
  const {
    mutateAsync: getProductListMutateAsync,
    data,
    isLoading,
  } = useGetProductsList();
  const fetchProductList = async () => {
    try {
      await getProductListMutateAsync({
        limit: countData,
        page: pageNumber,
        startDate: "",
        endDate: "",
        search: "",
      });
    } catch (error) {
      console.error("Error fetching product list:", error);
    }
  };
  React.useEffect(() => {
    fetchProductList();
  }, [countData, pageNumber]);

  // Mutation hooks for handling product add,edit,status and deletion
  const { mutateAsync: productAddMutatAsync, isLoading: addProductLoading } =
    useAddProduct();
  const { mutateAsync: productEditMutatAsync, isLoading: editProductLoading } =
    useEditProduct();
  const {
    mutateAsync: productStatusMutatAsync,
    isSuccess,
    data: statusData,
  } = useProductStatus();
  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success(statusData?.message);
    }
  }, [isSuccess]);
  const { mutateAsync: productDeleteMutatAsync } = useProductDelete();

  // formik for handle validation for add or edit product
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: INITIALVALUES.productInitialValues,
    validationSchema: VALIDATIONS.productValidationSchema,
    onSubmit: async () => {
      let formData = new FormData();
      formData.append("productName", values.productName);
      formData.append("price", values.price);
      formData.append("productLink", values.productLink);
      formData.append(
        "productImage",
        (productImage as never) || values.productImage
      );

      try {
        if (modalState.modalType === "Add") {
          let res: any = await productAddMutatAsync({ data: formData });
          if (res?.status === 201) {
            toast.success("Product added successfully");
            setModalState({
              ...modalState,
              open: false,
            });
            handleCloseModal();
            fetchProductList();
          }
        } else if (modalState.modalType === "Edit") {
          formData.append("id", modalState.data?._id);
          let res: any = await productEditMutatAsync({ data: formData });
          if (res?.status === 200) {
            toast.success("Product edit successfully");
            setModalState({
              ...modalState,
              open: false,
            });
            handleCloseModal();
            fetchProductList();
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.status === false) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error(error?.response?.data || error?.message);
        }
      }
    },
  });

  // Handle previous page navigation
  function handlePrevPage() {
    setPageNumber((prevPage: any) => prevPage - 1);
  }

  // Handle next page navigation
  function handleNextPage() {
    setPageNumber((nextPage: any) => nextPage + 1);
  }

  // Handle row limit change
  function handleRowLimitChange(newValue: number) {
    if (pageNumber === Math.ceil(data?.data?.totalRecords / countData)) {
      setPageNumber(1);
    }
    setCountData(newValue);
  }

  // Handle search input change
  async function handleSearch() {
    await getProductListMutateAsync({
      limit: countData,
      page: pageNumber,
      startDate:
        sortValue.from && sortValue.to ? dateFormatter(sortValue.from) : "",
      endDate:
        sortValue.to && sortValue.from ? dateFormatter(sortValue.to) : "",
      search: searchValue,
    });
  }

  // Handle clear filter values
  async function handleResetValues() {
    setSearchValue("");
    setSortValue({
      from: "",
      to: "",
    });
    fetchProductList();
  }

  // handle image select when add product
  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      setProductImage(file);
      setFieldValue("productImage", file);
    }
  }

  // Handle modal open
  function handleModalOpen(e: React.MouseEvent<HTMLButtonElement>, data?: any) {
    if (e.currentTarget.title === "Add") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.currentTarget.title,
        data: {},
        modalTitle: "Add Product",
      });
    } else if (e.currentTarget.title === "Edit") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.currentTarget.title,
        data: data,
        modalTitle: "Edit Product",
      });
    } else {
      return null;
    }
  }

  // getting data for edit
  React.useEffect(() => {
    if (modalState.modalType === "Edit") {
      setValues({
        productName: modalState.data?.productName || "",
        price: modalState.data?.price || "",
        productLink: modalState.data?.productLink || "",
        productImage: modalState.data?.productImage || null,
      });
    }
  }, [modalState.data]);

  // handle function for show modal
  function returnModalChild() {
    if (modalState.modalType === "Add" || modalState.modalType === "Edit") {
      return (
        <Modal
          loading={
            modalState.modalType === "Add"
              ? addProductLoading
              : editProductLoading
          }
          open={modalState.open}
          closeModalFn={handleCloseModal}
          saveFn={handleSubmit}
          title={modalState.modalTitle}
        >
          <ProductModal
            setProductImage={setProductImage}
            productImage={productImage}
            handleImageChange={handleImageChange}
            error={errors}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched}
            value={values}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  // close modal with reset values
  function handleCloseModal() {
    setModalState({
      ...modalState,
      open: false,
      data: {},
    });
    resetForm();
    setProductImage(null);
  }

  // Toggle active/inactive for product status change
  async function handleToggleStaus(item: IProductListProps) {
    try {
      let res: any = await productStatusMutatAsync({
        _id: item?._id,
        status: item?.status === "0" ? "1" : "0",
      });
      if (res?.status === 200) {
        fetchProductList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Handle delete product confirmation
  async function handleDeleteProduct(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the product?",
      confirmButtonText: "Delete",
      icon: "warning",
      onConfirm: async () => {
        try {
          let res: any = await productDeleteMutatAsync({
            _id: id,
          });
          if (res?.status === 200) {
            Swal.fire({
              title: "Deleted successfully",
              showConfirmButton: false,
              icon: "success",
              timer: 1200,
            });
            fetchProductList();
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: "An error occurred while deleting the user",
            icon: "error",
          });
        }
      },
    });
  }

  //handle production detail with data
  function onClickDetail(items: any) {
    nav(RoutePath.ProductDetail.path, { state: items?._id });
  }

  return (
    <Wrapper title={RoutePath.ProductManagement.title}>
      <CustomTable
        handleAddBtn={(e) => handleModalOpen(e)}
        handleReset={handleResetValues}
        response={data?.data?.products?.length === 0}
        notFoundTitle={"Products not found!"}
        sortValue={sortValue}
        setSortValue={setSortValue}
        loading={isLoading}
        currentPage={pageNumber}
        setPageNumber={setPageNumber}
        countData={countData}
        totalRecords={data?.data?.totalRecords}
        handleRowLimitChange={handleRowLimitChange}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handleSearch={handleSearch}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        title="Products Listing"
        tableHeader={productHeader}
        btnTitle="Add Product"
        isBtnRequired={true}
      >
        <ProductListing
          handleModalOpen={handleModalOpen}
          handleToggleStaus={handleToggleStaus}
          onClickDetail={onClickDetail}
          data={data?.data?.products}
          handleDeleteProduct={handleDeleteProduct}
        />
      </CustomTable>
      {returnModalChild()}
    </Wrapper>
  );
};
export default ProductManagement;
