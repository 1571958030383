/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { DeleteIcon, EditIcon } from "../../common/svg";
import { IBlogProps } from "../../../utils/interface";
import IMAGES from "../../../utils/constants/images";

type Props = {
  data: IBlogProps[];
  onClickDetail: (data: IBlogProps) => void;
  handleToggleStaus: (data: IBlogProps) => void;
  handleDeleteBlog: (_id: string) => void;
  handleModalOpen: (e: React.MouseEvent<HTMLButtonElement>, data: any) => void;
};

const BlogsListing: React.FC<Props> = ({
  handleModalOpen,
  onClickDetail,
  handleDeleteBlog,
  data,
  handleToggleStaus,
}) => {
  const videoRef = React.useRef<any>(null);

  return (
    <>
      {data?.map((items, index) => {
        return (
          <tr key={index} className="table-content">
            <td>
              <div className=" symbol-50px overflow-hidden me-3">
                <div>
                  {items?.imageLink ? (
                    <img
                      src={items?.imageLink}
                      alt="Product"
                      style={{
                        width: "100%",
                        height: "220px",
                        borderRadius: "20px",
                      }}
                    />
                  ) : (
                    <video
                      ref={videoRef}
                      src={items?.videoLink}
                      style={{ width: "100%", borderRadius: "20px" }}
                    />
                  )}
                </div>
              </div>
            </td>
            <td>{items?.title}</td>
            <td>{items?.description}</td>
            <td>
              <span
                className={
                  items?.status === "1"
                    ? "text-success fw-bolder"
                    : "text-danger fw-bolder"
                }
              >
                {items?.status === "1" ? "Active" : "Suspended"}
              </span>
            </td>
            <td>
              <span className="icons-main">
                <button className="table-trash">
                  <img
                    onClick={() => onClickDetail(items)}
                    width={19}
                    alt="view"
                    src={IMAGES.ICONS.viewIcon}
                  />
                </button>
                <button
                  title="Edit"
                  onClick={(e) => handleModalOpen(e, items)}
                  className="table-trash"
                >
                  <EditIcon />
                </button>
                <button
                  onClick={() => handleDeleteBlog(items?._id)}
                  className="table-trash"
                >
                  <DeleteIcon />
                </button>
                <span
                  onClick={() => handleToggleStaus(items)}
                  className="table-check"
                >
                  <input
                    type="checkbox"
                    id={items?._id}
                    checked={items?.status === "1" ? true : false}
                    readOnly
                  />
                  <label htmlFor={items?._id} />
                </span>
              </span>
            </td>
          </tr>
        );
      })}
    </>
  );
};
export default BlogsListing;
