export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="currentColor"
      className="bi bi-pen-fill"
      viewBox="0 0 16 16"
    >
      <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001" />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      width={17}
      height={17}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_921_6579)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.9162 17.0001H4.08406C3.15161 17.0001 2.39209 16.2406 2.39209 15.3082V3.5293H14.6066V15.3082C14.608 16.2406 13.8485 17.0001 12.9161 17.0001H12.9162ZM5.65441 14.6994C5.27948 14.6994 4.97378 14.3937 4.97378 14.0188V5.71523C4.97378 5.3403 5.27948 5.0346 5.65441 5.0346C6.03074 5.0346 6.33504 5.3403 6.33504 5.71523V14.0174C6.33644 14.3937 6.03064 14.6994 5.65441 14.6994ZM8.50016 14.6994C8.12533 14.6994 7.81953 14.3937 7.81953 14.0188V5.71523C7.81953 5.3403 8.12523 5.0346 8.50016 5.0346C8.87649 5.0346 9.18079 5.3403 9.18079 5.71523V14.0174C9.18079 14.3937 8.87639 14.6994 8.50016 14.6994ZM11.3459 14.6994C10.971 14.6994 10.6653 14.3937 10.6653 14.0188V5.71523C10.6653 5.3403 10.971 5.0346 11.3459 5.0346C11.7208 5.0346 12.0265 5.3403 12.0265 5.71523V14.0174C12.0265 14.3937 11.7207 14.6994 11.3458 14.6994H11.3459Z"
          fill="#000000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.8724 3.13052H1.12744C1.01678 3.13052 0.928223 3.04197 0.928223 2.9313V1.73738C0.928223 1.53258 1.09567 1.36523 1.30036 1.36523H15.6995C15.9043 1.36523 16.0716 1.53268 16.0716 1.73738V2.9313C16.0716 3.04197 15.9816 3.13052 15.8724 3.13052Z"
          fill="#000000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.4296 1.76528H6.56983C6.45916 1.76528 6.37061 1.67673 6.37061 1.56606V0.372142C6.37061 0.167344 6.53805 0 6.74275 0H10.2581C10.4629 0 10.6302 0.167444 10.6302 0.372142V1.56606C10.6288 1.67534 10.5403 1.76528 10.4296 1.76528Z"
          fill="#000000"
        />
      </g>
      <defs>
        <clipPath id="clip0_921_6579">
          <rect width={17} height={17} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const RevertDeleteIcon = () => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 157 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M56.9639 111.936C63.9719 111.936 66.2759 110.784 66.2759 117.312C66.2759 123.84 55.7159 121.344 38.2439 121.344C31.8119 121.344 34.2119 111.648 34.2119 94.656C34.2119 91.488 36.4199 89.28 39.5879 89.28C46.5959 89.28 44.9639 100.8 44.9639 107.904C45.1559 107.712 45.4439 106.944 45.5399 107.136C95.0759 57.024 137.412 124.32 105.348 154.944C94.2119 165.6 79.4279 169.344 64.1639 163.392C46.5959 156.576 36.1319 135.936 47.6519 135.936C55.7159 135.936 53.7959 155.904 79.6199 155.904C102.66 155.904 115.044 126.336 99.0119 108.48C86.5319 94.56 60.4199 98.784 56.8679 111.936H56.9639ZM11.6519 169.248C11.6519 184.416 27.1079 199.872 42.2759 199.872H114.276C129.444 199.872 144.9 184.416 144.9 169.248V54.624H11.5559V169.248H11.6519Z"
        fill="black"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M34.308 21.312C26.628 21.312 16.26 20.736 8.96398 21.312C-3.51602 22.272 -1.11602 33.312 5.02798 33.312H151.716C157.86 33.312 159.78 22.752 149.028 21.408C142.596 20.544 129.54 21.312 122.436 21.312C120.612 13.632 114.468 0 105.06 0H51.78C41.796 0 35.844 15.264 34.404 21.312H34.308Z"
        fill="black"
      />
    </svg>
  );
};

export const RemoveIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="2.82853"
        height="22.6283"
        rx="1"
        transform="matrix(0.707107 0.707107 0.707107 -0.707107 0 15.9995)"
        fill="black"
      />
      <rect
        width="2.82853"
        height="22.6283"
        rx="1"
        transform="matrix(-0.707107 0.707107 0.707107 0.707107 2 -0.000488281)"
        fill="black"
      />
    </svg>
  );
};

export const ChangePasswordIcon = () => {
  return (
    <svg
      width={16}
      height={16}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      fill="none"
    >
      <path
        fill="#ffff"
        d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
      />
    </svg>
  );
};

export const AddICon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7502 13.5396H13.5418V18.748C13.5418 19.0242 13.4321 19.2892 13.2367 19.4845C13.0414 19.6799 12.7764 19.7896 12.5002 19.7896C12.2239 19.7896 11.9589 19.6799 11.7636 19.4845C11.5682 19.2892 11.4585 19.0242 11.4585 18.748V13.5396H6.25016C5.9739 13.5396 5.70894 13.4299 5.51359 13.2345C5.31824 13.0392 5.2085 12.7742 5.2085 12.498C5.2085 12.2217 5.31824 11.9567 5.51359 11.7614C5.70894 11.566 5.9739 11.4563 6.25016 11.4563H11.4585V6.24797C11.4585 5.9717 11.5682 5.70675 11.7636 5.5114C11.9589 5.31605 12.2239 5.2063 12.5002 5.2063C12.7764 5.2063 13.0414 5.31605 13.2367 5.5114C13.4321 5.70675 13.5418 5.9717 13.5418 6.24797V11.4563H18.7502C19.0264 11.4563 19.2914 11.566 19.4867 11.7614C19.6821 11.9567 19.7918 12.2217 19.7918 12.498C19.7918 12.7742 19.6821 13.0392 19.4867 13.2345C19.2914 13.4299 19.0264 13.5396 18.7502 13.5396Z"
        fill="#fff"
      />
    </svg>
  );
};
