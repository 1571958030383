/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../utils/validation";
import { useAdminLogin } from "../../react-query/hooks/auth";
import userGlobalConfig from "../../utils/constants/globalConfig";
import { useAppContext } from "../../context/authContext";
import { toast } from "react-custom-alert";
import { DotLoader } from "react-spinners";
import { CustomInput } from "../../components/common/input";
import { Strings } from "../../utils/constants/strings";

const Login: React.FC = () => {
  // Getting isAuthenticated from app context
  const { setisAuthenticated } = useAppContext();

  // State for showing or hiding password
  const [show, setShow] = React.useState(false);

  // Getting login mutation and loading state
  const { mutateAsync, isLoading } = useAdminLogin();

  // Formik form handling
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
    initialValues: INITIALVALUES.loginInitialValues,
    validationSchema: VALIDATIONS.loginValidationSchema,
    onSubmit: async (values) => {
      try {
        const response: any = await mutateAsync(values)
        if (response.status === true) {
          toast.success("Login successfully")
          localStorage.setItem(userGlobalConfig.GLUCO_ADMIN_TOKEN, response?.data?.token);
          localStorage.setItem(userGlobalConfig.GLUCO_ADMIN_DETAIL, JSON.stringify(response?.data?.admin));
          resetForm()
          setisAuthenticated(true)
        }
      }
      catch (error: any) {
        if (error?.response?.data?.status === false) {
          toast.error(error?.response?.data?.message)
        }
        else {
          toast.error(error?.response?.data || error?.message)
        }
      }
    }
  });
  return (
    <div className="d-flex flex-column flex-root vh-100">
      <div className="d-flex flex-column flex-lg-row flex-column-fluid">
        <div className="d-flex flex-column flex-lg-row-auto bg-primary w-xl-600px positon-xl-relative">
          <div
            className="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px "
            style={{ backgroundColor: "inherit" }}>
            <div className="d-flex flex-row-fluid flex-column text-center p-5 p-lg-10 pt-lg-20">
              <a className="py-2 py-lg-20">
                <img
                  alt="Logo"
                  src="/assets/media/main-logos/GlucoLogo.svg"
                  className="h-90px h-lg-100px"
                />
              </a>
              <h1 className="d-none d-lg-block fw-bold text-white fs-2qx pb-5 pb-md-10">
                {Strings.welcome_to_gluco}
              </h1>
              <p className="d-none d-lg-block fw-semibold fs-4 text-white">
                Enter your {Strings.email} and {Strings.password} to access your
                <br /> account
              </p>
            </div>
            <div
              className="d-none d-lg-block d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
              style={{ backgroundImage: "url(/assets/media/illustrations/verification.png)" }} />
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row-fluid py-10 login-bg">
          <div className="d-flex flex-center flex-column flex-column-fluid">
            <div className="w-xl-700px w-100 p-10 p-lg-15 mx-auto z-1">
              <form onSubmit={handleSubmit} className="form w-100 bg-white p-8 rounded-3 " >
                <div className="text-center mb-6">
                  <h1 className="text-dark fs-3">Admin Login</h1>
                </div>
                <div className=" mb-10 fv-plugins-icon-container">
                  <label className="form-label fs-6 fw-bold text-dark">
                    {Strings.email}*
                  </label>
                  <CustomInput
                    className="form-control form-control-lg form-control-solid"
                    value={values.email}
                    name='email'
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.email}
                    touched={touched.email}
                    placeholder='Enter Your Email'
                  />
                </div>
                <div className="fv-row mb-10 fv-plugins-icon-container position-relative">
                  <div className="d-flex flex-stack mb-2">
                    <label className="form-label fw-bold text-dark fs-6 mb-0">
                      {Strings.password}*
                    </label>
                  </div>
                  <CustomInput
                    className="form-control form-control-lg form-control-solid position-relative"
                    value={values.password}
                    name='password'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    errors={errors.password}
                    touched={touched.password}
                    type={show ? "text" : "password"}
                    placeholder='Enter User Password'
                  />
                  <div onClick={() => setShow(!show)} className='password-eye'>
                    {values.password ? (show ? <MdOutlineRemoveRedEye /> : <RiEyeCloseLine />) : null}
                  </div>
                </div>
                <div className="text-center">
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-lg btn-primary w-100 mb-5">
                    {isLoading ? <DotLoader size={20} color="#fff" /> : " Login"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
export default Login