import * as Yup from 'yup';

// initialValues
const loginInitialValues = {
    email: "",
    password: ""
}

// validation schema
const loginValidationSchema = Yup.object().shape({
    email: Yup.string().required("Please enter your email*"),
    password: Yup.string().min(5).max(15).required("Please enter your password*")
});

export {
    loginInitialValues,
    loginValidationSchema,
}