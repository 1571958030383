/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { formatDate } from "../../../utils/helpers";
import IMAGES from "../../../utils/constants/images";
import { IUserListProps } from "../../../utils/interface";
import { DeleteIcon, RevertDeleteIcon } from "../../common/svg";

type Props = {
  data: IUserListProps[];
  onClickDetail: (data: IUserListProps) => void;
  handleToggleStaus: (data: IUserListProps) => void;
  handleDeleteUser: (data: IUserListProps) => void;
};

function UserListing({
  data,
  handleDeleteUser,
  handleToggleStaus,
  onClickDetail,
}: Props): JSX.Element | any {
  return data?.map((item: IUserListProps) => {
    let phoneNumber = item?.countryCode + item?.mobileNumber || "N/A";
    return (
      <tr className="table-content">
        <td className="d-flex align-items-center">
          <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
            <a>
              <div className="symbol-label">
                {item?.profileImage ? (
                  <img
                    src={item?.profileImage}
                    alt="Emma Smith"
                    className="w-100"
                  />
                ) : (
                  <span>{item?.firstName.charAt(0)}</span>
                )}
              </div>
            </a>
          </div>
          <div className="d-flex flex-column">
            <span className="text-gray-800 text-capitalize mb-1">
              {item?.firstName} {item?.lastName}
            </span>
          </div>
        </td>
        <td>{item?.email}</td>
        <td>{phoneNumber}</td>
        <td>{item?.age ? formatDate(item?.age) : "N/A"}</td>
        <td>
          {item?.gender === "0"
            ? "Other"
            : item?.gender === "1"
            ? "Male"
            : item?.gender === "2"
            ? "Female"
            : "N/A"}
        </td>
        <td>
          <span
            className={
              item?.isDeleted
                ? "text-danger fw-bolder"
                : item?.status === "1"
                ? "text-success fw-bolder"
                : "text-danger fw-bolder"
            }
          >
            {item?.isDeleted
              ? "Deleted"
              : item?.status === "1"
              ? "Active"
              : "Suspended"}
          </span>
        </td>
        <td>
          <span className="icons-main">
            <img
              onClick={() => onClickDetail(item)}
              width={19}
              alt="view"
              src={IMAGES.ICONS.viewIcon}
            />
            {item?.isDeleted ? (
              <button
                onClick={() => handleDeleteUser(item)}
                className="table-trash"
              >
                <RevertDeleteIcon />
              </button>
            ) : (
              <button
                onClick={() => handleDeleteUser(item)}
                className="table-trash"
              >
                <DeleteIcon />
              </button>
            )}
            <span
              onClick={() => !item?.isDeleted && handleToggleStaus(item)}
              className={
                item?.isDeleted ? "table-disabled-check d-flex" : "table-check"
              }
            >
              <input
                type="checkbox"
                id={item?._id}
                checked={item?.status === "1" ? true : false}
              />
              <label htmlFor={item?._id} />
            </span>
          </span>
        </td>
      </tr>
    );
  });
}
export default UserListing;
