/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Wrapper from "../../../components/header/headerWrapper";
import { IDonationProps, ModalState } from "../../../utils/interface";
import CustomTable from "../../../components/common/table";
import { donationHeader } from "../../../utils/seeds";
import DonationListing from "../../../components/screens/donation/donationListing";
import {
  useAddDonation,
  useDonationDelete,
  useDonationStatus,
  useEditDonation,
  useGetDonatoinsList,
} from "../../../react-query/hooks/donation";
import CustomAlert from "../../../components/common/alert";
import Swal from "sweetalert2";
import { dateFormatter } from "../../../utils/helpers";
import { useFormik } from "formik";
import { INITIALVALUES, VALIDATIONS } from "../../../utils/validation";
import { toast } from "react-custom-alert";
import Modal from "../../../components/common/modal";
import OrganizationModal from "../../../components/screens/donation/organizationModal";

const DonationLinksListing: React.FC = () => {
  // State variables for managing data pagination and filtering
  const [countData, setCountData] = React.useState<any>(10);
  const [pageNumber, setPageNumber] = React.useState<any>(1);
  const [searchValue, setSearchValue] = React.useState("");
  const [sortValue, setSortValue] = React.useState<any>({
    from: "",
    to: "",
  });
  const [donationLogo, setDonationLogo] = React.useState<any>(null);
  const [modalState, setModalState] = React.useState<ModalState>({
    open: false,
    modalType: "",
    modalTitle: "",
    data: {},
  });

  // Hook to fetch donation links data
  const {
    mutateAsync: getDonationListMutateAsync,
    data,
    isLoading,
  } = useGetDonatoinsList();
  const fetchDonationList = async () => {
    try {
      await getDonationListMutateAsync({
        limit: countData,
        page: pageNumber,
        startDate: "",
        endDate: "",
        search: "",
      });
    } catch (error) {
      console.error("Error fetching donation list:", error);
    }
  };
  React.useEffect(() => {
    fetchDonationList();
  }, [countData, pageNumber]);

  // Mutation hooks for handling dontion add,edit,status and deletion
  const { mutateAsync: donationAddMutatAsync, isLoading: addDonationLoading } =
    useAddDonation();
  const {
    mutateAsync: donationEditMutatAsync,
    isLoading: editDonationLoading,
  } = useEditDonation();
  const { mutateAsync: donationDeleteMutatAsync } = useDonationDelete();
  const {
    mutateAsync: donationStatusUpdateMutatAsync,
    isSuccess,
    data: statusData,
  } = useDonationStatus();
  React.useEffect(() => {
    if (isSuccess === true) {
      toast.success(statusData?.message);
    }
  }, [isSuccess]);

  // Handle previous page navigation
  function handlePrevPage() {
    setPageNumber((prevPage: any) => prevPage - 1);
  }

  // Handle next page navigation
  function handleNextPage() {
    setPageNumber((nextPage: any) => nextPage + 1);
  }

  // Handle row limit change
  function handleRowLimitChange(newValue: number) {
    if (pageNumber === Math.ceil(data?.data?.totalRecords / countData)) {
      setPageNumber(1);
    }
    setCountData(newValue);
  }

  // Handle search input change
  async function handleSearch() {
    await getDonationListMutateAsync({
      limit: countData,
      page: pageNumber,
      startDate:
        sortValue.from && sortValue.to ? dateFormatter(sortValue.from) : "",
      endDate:
        sortValue.to && sortValue.from ? dateFormatter(sortValue.to) : "",
      search: searchValue,
    });
  }

  // Handle clear filter values
  async function handleResetValues() {
    setSearchValue("");
    setSortValue({
      from: "",
      to: "",
    });
    fetchDonationList();
  }

  // handle image select when add product
  function handleImageChange(e: React.ChangeEvent<HTMLInputElement>) {
    const file = e.target.files?.[0];
    if (file) {
      setDonationLogo(file);
      setFieldValue("image", file);
    }
  }

  // formik for handle validation for add or edit donation
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: INITIALVALUES.donationInitialValues,
    validationSchema: VALIDATIONS.donationValidationSchema,
    onSubmit: async () => {
      let formData = new FormData();
      formData.append("title", values.title);
      formData.append("link", values.link);
      formData.append("image", (donationLogo as never) || values.image);
      try {
        if (modalState.modalType === "Add") {
          let res: any = await donationAddMutatAsync({ data: formData });
          if (res?.status === 201) {
            toast.success("Donation added successfully");
            setModalState({
              ...modalState,
              open: false,
            });
            handleCloseModal();
            fetchDonationList();
          }
        } else if (modalState.modalType === "Edit") {
          formData.append("id", modalState.data?._id);
          let res: any = await donationEditMutatAsync({ data: formData });
          if (res?.status === 200) {
            toast.success("Donation edit successfully");
            setModalState({
              ...modalState,
              open: false,
            });
            handleCloseModal();
            fetchDonationList();
          }
        }
      } catch (error: any) {
        if (error?.response?.data?.status === false) {
          toast.error(error?.response?.data?.message);
        } else {
          toast.error("Donation link must be a valid url"|| error?.message);          
        }
      }
    },
  });

  // Handle modal open for add or edit
  function handleModalOpen(e: React.MouseEvent<HTMLButtonElement>, data?: any) {
    if (e.currentTarget.title === "Add") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.currentTarget.title,
        data: {},
        modalTitle: "Add Organization",
      });
    } else if (e.currentTarget.title === "Edit") {
      setModalState({
        ...modalState,
        open: true,
        modalType: e.currentTarget.title,
        data: data,
        modalTitle: "Edit Organization",
      });
    } else {
      return null;
    }
  }

  // getting data when edit donation
  React.useEffect(() => {
    setValues({
      title: modalState.data?.title || "",
      link: modalState.data?.link || "",
      image: modalState.data?.image || null,
    });
  }, [modalState.data]);

  // close modal with reset values
  function handleCloseModal() {
    setModalState({
      ...modalState,
      open: false,
      data: {},
    });
    resetForm();
    setDonationLogo(null);
  }

  // handle function for show modal
  function returnModalChild() {
    if (modalState.modalType === "Add" || modalState.modalType === "Edit") {
      return (
        <Modal
          loading={
            modalState.modalType === "Add"
              ? addDonationLoading
              : editDonationLoading
          }
          open={modalState.open}
          closeModalFn={handleCloseModal}
          saveFn={handleSubmit}
          title={modalState.modalTitle}
        >
          <OrganizationModal
            donationLogo={donationLogo}
            handleImageChange={handleImageChange}
            error={errors}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={touched}
            value={values}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  // Toggle active/inactive for product status change
  async function handleToggleStaus(item: IDonationProps) {
    try {
      let res: any = await donationStatusUpdateMutatAsync({
        id: item?._id,
        status: item?.status === "0" ? "1" : "0",
      });
      if (res?.status === 200) {
        fetchDonationList();
      }
    } catch (error) {
      console.log(error);
    }
  }

  // Handle delete donation confirmation
  async function handleDeleteDonation(id: string) {
    CustomAlert({
      title: " Are you sure want to delete the donation?",
      confirmButtonText: "Delete",
      icon: "warning",
      onConfirm: async () => {
        try {
          let res: any = await donationDeleteMutatAsync({
            _id: id,
          });
          if (res?.status === 200) {
            Swal.fire({
              title: "Deleted successfully",
              showConfirmButton: false,
              icon: "success",
              timer: 1200,
            });
            fetchDonationList();
          }
        } catch (error) {
          Swal.fire({
            title: "Error",
            text: "An error occurred while deleting the donation",
            icon: "error",
            timer: 1000,
            showConfirmButton: false,
          });
        }
      },
    });
  }
  return (
    <Wrapper title="Donation Organization Management">
      <CustomTable
        handleAddBtn={(e) => handleModalOpen(e)}
        handleReset={handleResetValues}
        response={data?.data?.donation?.length === 0}
        notFoundTitle={"Data not found!"}
        sortValue={sortValue}
        setSortValue={setSortValue}
        loading={isLoading}
        currentPage={pageNumber}
        setPageNumber={setPageNumber}
        countData={countData}
        totalRecords={data?.data?.totalRecords}
        handleRowLimitChange={handleRowLimitChange}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        handleSearch={handleSearch}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        title="Donation Organization Listing"
        tableHeader={donationHeader}
        btnTitle="Add New Organization"
        isBtnRequired={true}
      >
        <DonationListing
          handleModalOpen={handleModalOpen}
          handleToggleStaus={handleToggleStaus}
          handleDeleteDonation={handleDeleteDonation}
          data={data?.data?.donation}
        />
      </CustomTable>
      {returnModalChild()}
    </Wrapper>
  );
};
export default DonationLinksListing;
