import Welcome from "../components/landingPage"
import adminScreens from "../pages"

const RoutePath = {
    Landing: {
        path: '/',
        component: <Welcome />,
        type: 'public',
    },
    Login: {
        path: '/login',
        title: 'Login',
        component: <adminScreens.Login />,
        type: 'public',
    },
    DeleteAccount: {
        path: '/deleteaccount',
        title: 'Delete Account',
        component: <adminScreens.DeleteAccount />,
        type: 'public',
    },
    UsersManagement: {
        path: '/usersmanagement',
        title: 'Users Management',
        component: <adminScreens.UsersManagement />,
        type: 'private',
    },
    UsersDetail: {
        path: '/userdetail',
        title: 'User Detail',
        component: <adminScreens.UserDetail />,
        type: 'private',
    },
    ProductManagement: {
        path: '/productmanagement',
        title: 'Products Management',
        component: <adminScreens.ProductManagement />,
        type: 'private',
    },
    ProductDetail: {
        path: '/productdetail',
        title: 'Product Detail',
        component: <adminScreens.ProductDetail />,
        type: 'private',
    },
    DonationLinks: {
        path: '/donationlinks',
        title: 'Donation Links',
        component: <adminScreens.DonationLinksListing />,
        type: 'private',
    },
    BlogsManagement: {
        path: '/blogsmanagement',
        title: 'Blogs Management',
        component: <adminScreens.BlogsManagement />,
        type: 'private',
    },
    BlogDetail: {
        path: '/blogdetail',
        title: 'Blog Detail',
        component: <adminScreens.BlogDetail />,
        type: 'private',
    },
    ContentManagement: {
        path: '/contentsmanagement',
        title: 'Contents Management',
        component: <adminScreens.ContentManagement />,
        type: 'private',
    },
    TermsAndCondition: {
        path: '/termsandconditions',
        title: 'Terms And Conditions',
        component: <adminScreens.TermsAndConditons />,
        type: 'private',
    },
    PrivacyAndPolicy: {
        path: '/privacypolicy',
        title: 'Privacy policy',
        component: <adminScreens.PrivacyPolicy />,
        type: 'private',
    },
    About: {
        path: '/aboutus',
        title: 'About us',
        component: <adminScreens.AboutUs />,
        type: 'private',
    },
    Profile: {
        path: '/profile',
        title: 'Profile',
        component: <adminScreens.Profile />,
        type: 'private',
    },
    Notifications: {
        path: '/notifications',
        title: 'Notifications',
        component: <adminScreens.Notifications />,
        type: 'private',
    },
};
export default RoutePath