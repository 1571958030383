import { loginInitialValues, loginValidationSchema } from "./authValidation"
import { blogInitialValues, blogValidationSchema } from "./blog"
import { changePasswordSchema, initialValuesChangePassword } from "./changePassword"
import { donationInitialValues, donationValidationSchema } from "./donation"
import { productInitialValues, productValidationSchema } from "./product"
import { editProfileSchema, initialValuesEditProfile } from "./profile"

const INITIALVALUES = {
    loginInitialValues: loginInitialValues,
    initialValuesChangePassword: initialValuesChangePassword,
    productInitialValues: productInitialValues,
    donationInitialValues: donationInitialValues,
    initialValuesEditProfile: initialValuesEditProfile,
    blogInitialValues: blogInitialValues,
}

const VALIDATIONS = {
    loginValidationSchema: loginValidationSchema,
    changePasswordSchema: changePasswordSchema,
    productValidationSchema: productValidationSchema,
    donationValidationSchema: donationValidationSchema,
    editProfileSchema: editProfileSchema,
    blogValidationSchema: blogValidationSchema,
}

export {
    VALIDATIONS,
    INITIALVALUES
} 