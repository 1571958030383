const usersHeader = [
    {
        key: "1",
        value: "User Name",
    },
    {
        key: "2",
        value: "Email",
    },
    {
        key: "4",
        value: "Mobile",
    },
    {
        key: "4",
        value: "DOB",
    },
    {
        key: "5",
        value: "Gender",
    },
    {
        key: "6",
        value: "Status",
    },
    {
        key: "7",
        value: "Action",
    },
]

const productHeader = [
    {
        key: "1",
        value: "Image",
    },
    {
        key: "2",
        value: "Name",
    },
    {
        key: "3",
        value: "Price",
    },
    {
        key: "4",
        value: "Link",
    },
    {
        key: "5",
        value: "Status",
    },
    {
        key: "6",
        value: "Action",
    },
]

const donationHeader = [
    {
        key: "1",
        value: "Organization Logo",
    },
    {
        key: "2",
        value: "Organization Name",
    },
    {
        key: "3",
        value: "Link",
    },
    {
        key: "4",
        value: "Status",
    },
    {
        key: "5",
        value: "Action",
    },
]

const blogsHeader = [
    {
        key: "1",
        value: "Blog Logo/Video Thumbnail",
    },
    {
        key: "2",
        value: "Title",
    },
    {
        key: "3",
        value: "Description",
    },
    {
        key: "4",
        value: "Status",
    },
    {
        key: "5",
        value: "Action",
    },
];
const contentHeader = [
    {
        name: "Terms And Conditions",
        path: "/termsandconditions"

    },
    {
        name: "Privacy Policy",
        path: "/privacypolicy"
    },
    {
        name: "About Us",
        path: "/aboutus"
    },
]


export {
    usersHeader,
    productHeader,
    donationHeader,
    blogsHeader,
    contentHeader
}