/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
  title: string;
  info: any;
  isLink: boolean;
};

const ProuductDetailInfo = ({ title, info, isLink }: Props) => {
  return (
    <div className="product-info-blk">
      <span className="product-info-title">{title} :</span>
      {!isLink ? (
        <span className="product-info-title-name">{info}</span>
      ) : (
        <a onClick={()=>window.open(info)} className="product-info-title-name">{info}</a>
      )}
    </div>
  );
};

export default ProuductDetailInfo;
