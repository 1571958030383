import React, { useState, useEffect } from "react";
import RichTextEditor from "react-rte";

const ContentEditor = ({ markup, onChange }: any) => {
  const initialValue = RichTextEditor.createValueFromString(markup, "html");
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const newValue = RichTextEditor.createValueFromString(markup, "html");
    setValue(newValue);
  }, [markup]);

  const handleEditorChange = (newValue: any) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue.toString("html"));
    }
  };
  return <RichTextEditor value={value} onChange={handleEditorChange} />;
};

export default ContentEditor;
