/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import IMAGES from "../../utils/constants/images";
import { tabsArray } from "../../utils/seeds/tabsArray";
import { useAppContext } from "../../context/authContext";
import CustomAlert from "../common/alert";
import RoutePath from "../../routes/routesPath";
import userGlobalConfig from "../../utils/constants/globalConfig";
import { useGetProfileDetail } from "../../react-query/hooks/profile";

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedOut } = useAppContext();
  const [active, setActive] = React.useState(false);

  // Getting profile detail from local storage
  const item = localStorage.getItem(userGlobalConfig.GLUCO_ADMIN_DETAIL);

  // Fetching profile detail using custom hook
  const getProfileDetail = item ? JSON.parse(item) : null;
  const { data } = useGetProfileDetail({
    id: getProfileDetail?._id,
  });

  // Function to handle navigation based on the provided path.
  function handleNavigation(path: string) {
    navigate(path);
    setActive(false);
  }

  // Function to check if a link is currently active based on the pathname
  function isLinkActive(pathname: string | undefined) {
    return location.pathname === pathname;
  }

  // Function to handle logout action. Displays confirmation dialog before logging out.
  function handleLogout() {
    setActive(false);
    CustomAlert({
      title: " Are you sure want to logout?",
      confirmButtonText: "Logout",
      icon: "warning",
      onConfirm: async () => {
        isLoggedOut();
      },
    });
  }

  return (
    <Fragment>
      <div className="header-blk">
        <div className="container-fluid d-flex align-items-stretch justify-content-between gap-7">
          <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap me-lg-2 pb-2 pb-lg-0">
            <img
              onClick={() => navigate(RoutePath.UsersManagement.path)}
              src={IMAGES.LOGO.mainLogo}
              className="main-logo cursor-pointer"
            />
          </div>
          <ul className="navlist nav nav-pills">
            {tabsArray?.map((items, index) => (
              <li key={index} className="nav-item ">
                <button
                  onClick={() => handleNavigation(items.path)}
                  className={
                    isLinkActive(items?.path) || isLinkActive(items?.detailPath)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  {items.icon}
                  {items.name}
                </button>
              </li>
            ))}
          </ul>
          <div
            onClick={() => setActive(!active)}
            className="d-flex align-items-center position-relative show"
          >
            <div className="d-flex align-items-center text-white gap-2 cursor-pointer">
              <div className="cursor-pointer symbol symbol-30px symbol-md-40px">
                <img src={IMAGES.LOGO.profileLogo} />
              </div>
              <p className="m-0">{data?.data?.username}</p>
              <div>
                {active ? (
                  <img width={20} src={IMAGES.ICONS.dropUpIcon} />
                ) : (
                  <img width={20} src={IMAGES.ICONS.dropDownIcon} />
                )}
              </div>
            </div>
            <div className={`profile-card ${active && "profileblk_active"} `}>
              <ul>
                <li
                  className={
                    isLinkActive(RoutePath.Profile.path) ? "active" : ""
                  }
                  onClick={() => handleNavigation(RoutePath.Profile.path)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19"
                    height="19"
                    fill="currentColor"
                    className="bi bi-person-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
                  </svg>
                  Profile
                </li>
                {/* <li
                  className={
                    isLinkActive(RoutePath.Notifications.path) ? "active" : ""
                  }
                  onClick={() => handleNavigation(RoutePath.Notifications.path)}
                >
                  <span className="svg-icon svg-icon-1">
                    <img
                      width={18}
                      height={18}
                      src={IMAGES.LOGO.notificationLogo}
                    />
                  </span>
                  Notifications
                </li> */}
                <li onClick={handleLogout}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.2049 19.125C29.2098 21.1587 28.6612 23.1553 27.6178 24.901C26.5744 26.6466 25.0756 28.0753 23.282 29.034C21.4885 29.9927 19.4679 30.4452 17.4368 30.343C15.4057 30.2407 13.4407 29.5877 11.7524 28.4538C10.0642 27.3199 8.71642 25.7479 7.85353 23.9064C6.99065 22.0648 6.64523 20.0231 6.85429 18.0002C7.06335 15.9773 7.81902 14.0495 9.04027 12.4233C10.2615 10.7972 11.9023 9.53405 13.7868 8.76938C14.0583 8.67822 14.3544 8.69407 14.6147 8.81371C14.8749 8.93335 15.0798 9.14776 15.1874 9.41321C15.295 9.67867 15.2973 9.97518 15.1938 10.2423C15.0904 10.5094 14.8889 10.727 14.6305 10.8506C12.707 11.6333 11.1154 13.0601 10.1278 14.8868C9.14034 16.7136 8.81841 18.8268 9.21715 20.8648C9.61589 22.9027 10.7105 24.7388 12.3137 26.0587C13.9169 27.3786 15.9289 28.1003 18.0055 28.1003C20.0821 28.1003 22.0942 27.3786 23.6974 26.0587C25.3005 24.7388 26.3951 22.9027 26.7939 20.8648C27.1926 18.8268 26.8707 16.7136 25.8832 14.8868C24.8957 13.0601 23.304 11.6333 21.3805 10.8506C21.235 10.8018 21.1009 10.7236 20.9867 10.621C20.8725 10.5184 20.7805 10.3935 20.7164 10.254C20.6522 10.1145 20.6174 9.96333 20.6138 9.80983C20.6103 9.65633 20.6383 9.50374 20.696 9.36145C20.7537 9.21916 20.8399 9.09018 20.9493 8.98246C21.0587 8.87474 21.189 8.79055 21.3321 8.73509C21.4753 8.67962 21.6283 8.65405 21.7818 8.65995C21.9352 8.66584 22.0858 8.70308 22.2243 8.76938C24.2895 9.60021 26.0583 11.0309 27.3026 12.8767C28.5469 14.7226 29.2095 16.8989 29.2049 19.125ZM17.9999 18.3938C18.2983 18.3938 18.5844 18.2752 18.7954 18.0642C19.0064 17.8533 19.1249 17.5671 19.1249 17.2688V6.75C19.1249 6.45163 19.0064 6.16548 18.7954 5.95451C18.5844 5.74353 18.2983 5.625 17.9999 5.625C17.7015 5.625 17.4154 5.74353 17.2044 5.95451C16.9934 6.16548 16.8749 6.45163 16.8749 6.75V17.2688C16.8749 17.4165 16.904 17.5628 16.9605 17.6993C17.0171 17.8358 17.0999 17.9598 17.2044 18.0642C17.3089 18.1687 17.4329 18.2516 17.5694 18.3081C17.7059 18.3647 17.8522 18.3938 17.9999 18.3938Z"
                      fill="red"
                    />
                  </svg>
                  Logout
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Header;
