import { useMutation, useQuery, useQueryClient } from "react-query";
import ENDPOINTS from "../services/endPoints";
import { Auth } from "../../utils/interface";
import { mutateDeleteFn, mutateGetFn, mutatePostFn, mutatePutFn, QueryReturnType } from "../services/networking.service";

interface IProps {
    data: FormData
}

export const useAddBlog = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IProps>(data => mutatePostFn(ENDPOINTS.ADD_BLOG, data.data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useEditBlog = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, IProps>(data => mutatePutFn(ENDPOINTS.EDIT_BLOG, data.data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
}

export const useGetBlogsList = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>((data) => mutateGetFn(ENDPOINTS.BLOGS_LIST, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
};

export const useGetBlogDetail = ({ id }: any): QueryReturnType<any> => {
    return useQuery<never, never, any>(
        [ENDPOINTS.BLOG_DETAIL, { id: id }],
        {
            notifyOnChangeProps: ['data', 'error', 'refetch', 'isRefetching', 'isSuccess', 'isLoading'],
        }
    )
};

export const useBlogStatus = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>(data => mutatePutFn(ENDPOINTS.BLOG_STATUS, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
};

export const useBlogDelete = () => {
    const queryClient = useQueryClient()
    return useMutation<Auth, never, any>(data => mutateDeleteFn(ENDPOINTS.BLOG_DELETE, data), {
        onSuccess: async () => {
            queryClient.invalidateQueries([])
        },
    })
};