/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useAppContext } from '../context/authContext';
import { toast } from 'react-custom-alert';
import userGlobalConfig from './constants/globalConfig';

let instance = 0
function LogoutSession() {
    const { setisAuthenticated } = useAppContext();
    const [wantToLogout, setWantToLogOut] = useState(false)

    React.useEffect(() => {
        window.addEventListener('storage', storageEventHandler, false);
    }, []);

    function storageEventHandler() {
        setWantToLogOut(localStorage?.getItem(userGlobalConfig?.GLUCO_LOGOUT) === 'true' ? true : false)
        if (localStorage?.getItem(userGlobalConfig?.GLUCO_LOGOUT) === 'true') {
            instance = instance + 1
        }
    };

    React.useEffect(() => {
        if (wantToLogout && instance === 1) {
            setisAuthenticated(false);
            localStorage.clear();
            toast.error("Session expired")
        }
        setWantToLogOut(false)
        localStorage?.setItem(userGlobalConfig?.GLUCO_LOGOUT, 'false')
        window.dispatchEvent(new Event('storage'))
    }, [wantToLogout])

    return null
};
export default LogoutSession