import React from "react";
import RoutePath from "../../../routes/routesPath";
import Wrapper from "../../../components/header/headerWrapper";
import IMAGES from "../../../utils/constants/images";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetProductDetail } from "../../../react-query/hooks/product";
import { IProductListProps } from "../../../utils/interface";
import ProuductDetailInfo from "../../../components/screens/product/detailInfo";
import { Strings } from "../../../utils/constants/strings";

const ProductDetail: React.FC = () => {
    // Navigation function
    const nav = useNavigate();

    // Get current location
    const location = useLocation();

    // Fetch product details using custom hook
    const { data } = useGetProductDetail({
        _id: location?.state
    });

    // Extracting product detail data
    const getProductDetail: IProductListProps = data?.data;

    return (
        <Wrapper title={RoutePath.ProductManagement.title}>
            <div className="container-xxl" >
                <div className="card">
                    <div className="card-wrapper pb-10">
                        <div className="heading-flex-box">
                            <div className="sub-heading common-sub-heading d-flex align-items-center gap-3">
                                <img width={"20"} className='cursor-pointer' src={IMAGES.ICONS.backIcon} alt='' onClick={() => nav(- 1)} />
                                <h2>{RoutePath.ProductDetail.title}</h2>
                            </div>
                        </div>
                        <div className='row row-cols-1 row-cols-sm-2 row-cols-xl-2 row-12 g-5'>
                            <div className="col">
                                <div className="custmorDetail-img-blk">
                                    <img
                                        src={getProductDetail?.productImage}
                                        alt='profile'
                                    />
                                </div>
                            </div>
                            <div className="col p-14">
                                <ProuductDetailInfo isLink={false} info={getProductDetail?.productName} title={Strings.product_name} />
                                <ProuductDetailInfo isLink={false} info={`$${getProductDetail?.price}`} title={Strings.product_price} />
                                <ProuductDetailInfo isLink={true} info={getProductDetail?.productLink} title={Strings.product_link} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    )
};
export default ProductDetail