import React, { useState } from "react";
import RoutePath from "../../routes/routesPath";
import Wrapper from "../../components/header/headerWrapper";
import { Strings } from "../../utils/constants/strings";
import EditProfile from "./editProfile";
import userGlobalConfig from "../../utils/constants/globalConfig";
import { useGetProfileDetail } from "../../react-query/hooks/profile";
import ChangePassword from "./changePassword";
import IMAGES from "../../utils/constants/images";
import { useNavigate } from "react-router-dom";
import { ChangePasswordIcon, EditIcon } from "../../components/common/svg";

const Profile: React.FC = () => {
  const nav = useNavigate();
  // State variables for showing edit section and change password block
  const [showEditSection, setShowEditSection] = useState(false);
  const [showChangePasswordBlk, setShowChangePasswordBlk] = useState(false);

  // Getting profile detail from local storage
  const item = localStorage.getItem(userGlobalConfig.GLUCO_ADMIN_DETAIL);

  // Fetching profile detail using custom hook
  const getProfileDetail = item ? JSON.parse(item) : null;
  const { data } = useGetProfileDetail({
    id: getProfileDetail?._id,
  });

  return (
    <Wrapper title={RoutePath.Profile.title}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <img
                width={"20"}
                className="cursor-pointer ms-6 mt-3"
                src={IMAGES.ICONS.backIcon}
                alt=""
                onClick={() => nav(-1)}
              />
              <div className="card-body">
                <div className="row">
                  <div className="col-12">
                    <div className="profile-img-wrapper">
                      <div className="profile-info-wrapper">
                        <div className="profile-blk">
                          <img
                            src={IMAGES.LOGO.profileDefaultLogo}
                            alt="Profile"
                          />
                          <div className={`profile-img-upload`}>
                            <input
                              type="file"
                              id="profileImg"
                              className="d-none"
                            />
                            <label htmlFor="profileImg">
                              <svg
                                width={16}
                                height={16}
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.0596 0.489315C11.3891 0.15981 11.9234 0.159813 12.2529 0.489315L15.5114 3.74781C15.8409 4.07733 15.8409 4.61156 15.5114 4.94105L5.30921 15.1432C5.29318 15.1597 5.27613 15.1752 5.2582 15.1896C5.1875 15.2464 5.10622 15.2836 5.02167 15.3013C5.00813 15.3041 4.99446 15.3065 4.98061 15.3083L0.761848 15.8708C0.588183 15.894 0.413643 15.8349 0.289756 15.711C0.165872 15.5871 0.106785 15.4126 0.129939 15.2389L0.691592 11.0265C0.706174 10.9059 0.759728 10.7892 0.852252 10.6967L11.0596 0.489315ZM12.8295 6.03195L4.90621 13.9552L2.04547 11.0945L9.96875 3.17121L12.8295 6.03195ZM13.625 5.23647L14.517 4.34445L11.6562 1.48369L10.7642 2.3757L13.625 5.23647ZM1.65713 12.2971L1.34229 14.6585L3.70362 14.3436L1.65713 12.2971Z"
                                  fill="#ffffff"
                                />
                              </svg>
                            </label>
                          </div>
                        </div>
                        <div className="profile-info">
                          <h2>{data?.data?.username}</h2>
                          <p>Admin</p>
                        </div>
                      </div>
                      <div className="profile-btn-blk">
                        <button
                          className="edit-btn"
                          onClick={() => setShowEditSection(true)}
                        >
                          <EditIcon />
                          {Strings.edit} Profile
                        </button>
                        <button
                          className="edit-btn"
                          onClick={() => setShowChangePasswordBlk(true)}
                        >
                          <ChangePasswordIcon />
                          {Strings.change_password}
                        </button>
                      </div>
                    </div>
                    <EditProfile
                      data={data?.data}
                      setShowBtnBlk={setShowEditSection}
                      showBtnBlk={showEditSection}
                    />
                  </div>
                </div>
                <ChangePassword
                  id={getProfileDetail?._id}
                  showChangePasswordBlk={showChangePasswordBlk}
                  setShowChangePasswordBlk={setShowChangePasswordBlk}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Profile;
