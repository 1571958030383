/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { DeleteIcon, EditIcon } from "../../common/svg";
import { IDonationProps } from "../../../utils/interface";

type Props = {
    data: IDonationProps[];
    handleToggleStaus: (data: IDonationProps) => void;
    handleDeleteDonation: (_id: string) => void;
    handleModalOpen: (e: React.MouseEvent<HTMLButtonElement>, data: IDonationProps) => void;
};

const DonationListing: React.FC<Props> = ({ data, handleDeleteDonation, handleToggleStaus, handleModalOpen }) => {
    return (
        <>
            {data?.map((items, index) => (
                <tr key={index} className="table-content">
                    <td>
                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                            <div onClick={() => window.open(items.image)} className="symbol-label">
                                <img
                                    src={items.image}
                                    alt="Product"
                                    className="w-100"
                                />
                            </div>
                        </div>
                    </td>
                    <td>{items.title}</td>
                    <td> <a onClick={() => window.open(items.link)} className="text-decoration-underline cursor-pointer">
                        {items.link}
                    </a>
                    </td>
                    <td>
                        <span
                            className={
                                items?.status === "1"
                                    ? "text-success fw-bolder"
                                    : "text-danger fw-bolder"
                            }>
                            {items?.status === "1" ? "Active" : "Suspended"}
                        </span>
                    </td>
                    <td>
                        <span className="icons-main">
                            <button
                                title="Edit"
                                onClick={(e) => handleModalOpen(e, items)}
                                className="table-trash">
                                <EditIcon />
                            </button>
                            <button
                                onClick={() => handleDeleteDonation(items._id)}
                                className="table-trash">
                                <DeleteIcon />
                            </button>
                            <span
                                onClick={() => handleToggleStaus(items)}
                                className="table-check">
                                <input
                                    type="checkbox"
                                    id={items._id}
                                    checked={items?.status === "1" ? true : false}
                                    readOnly
                                />
                                <label htmlFor={items._id} />
                            </span>
                        </span>
                    </td>
                </tr>
            ))}
        </>
    );
};
export default DonationListing