import React from 'react'

type Props = {
    title: string,
    info: string
}

const DetailInfo = ({ title, info }: Props) => {
    return (
        <div className="customer-info-blk">
            <span className="customer-info-title">{title}:</span>
            <span>{info}</span>
        </div>
    )
}

export default DetailInfo