import * as Yup from 'yup';

// initialValues
const donationInitialValues = {
    title: "",
    link: "",
    image: null,
}

// validation schema
const donationValidationSchema = Yup.object().shape({
    title: Yup.string().required("Please enter your organization title*"),
    link: Yup.string().required("Please enter your organization link*"),
    image: Yup.string().required("Organization image is required*"),
});

export {
    donationInitialValues,
    donationValidationSchema,
}