import React from "react";
import { IContentsProps } from "../../utils/interface";

type Props = {
  data?: IContentsProps[];
};

const TermsAndConditons: React.FC<Props> = ({ data }) => {
  // Filter out content with type === "0"
  const filteredData = data?.filter((item) => item.type === "0");

  //  render TermsAndConditions with data
  function renderTermsAndConditions() {
    return filteredData?.length ? (
      filteredData.map((item) => (
        <div key={item._id}>
          <p dangerouslySetInnerHTML={{ __html: item.content }} />
        </div>
      ))
    ) : (
      <p className="text-center m-10 fs-3">
        Terms and conditions not available!
      </p>
    );
  }

  return (
    <div className="container-xxl">
      <div className="card" style={{ maxHeight: "500px", overflowY: "auto" }}>
        <div className="card-wrapper">{renderTermsAndConditions()}</div>
      </div>
    </div>
  );
};
export default TermsAndConditons;
