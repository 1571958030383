/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import IMAGES from "../../utils/constants/images";
import { useAppContext } from "../../context/authContext";
import { useNavigate } from "react-router-dom";
import RoutePath from "../../routes/routesPath";

const Welcome: React.FC = () => {
    const { isAuthenticated } = useAppContext();
    const navigate = useNavigate();

    React.useEffect(() => {
        const timer = setTimeout(() => {
            navigate(isAuthenticated
                ? RoutePath.UsersManagement.path
                : RoutePath.Login.path);
        }, 5000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <main className="landing-page">
            <div className="landing-container">
                <div style={{ height: "100vh" }}>
                    <img
                        style={{
                            maxWidth: "100%",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                        }}
                        src={IMAGES.LOGO.welcomeLogo}
                    />
                </div>
            </div>
        </main>
    );
};

export default Welcome;