const adminApiEndURL = {
  // auth
  ADMIN_LOGIN: "auth/login",

  //user management
  USERS_LIST: "/usersList",
  USER_DETAIL: "/userDetail",
  USER_STATUS: "/changeStatus",
  USER_DELETE: "/userDelete",
  REVERT_USER_DELETE: "/revert-delete-user",

  //product management
  ADD_PRODUCT: "/addProduct",
  EDIT_PRODUCT: "/updateProduct",
  PRODUCT_LIST: "/productList",
  PRODUCT_DETAIL: "/productDetails",
  PRODUCT_STATUS: "/changeProductStatus",
  PRODUCT_DELETE: "/deleteProduct",

  //donation links
  ADD_DONATION: "/addDonation",
  EDIT_DONATION: "/editDonation",
  DONATION_LIST: "/getDonations",
  DONATION_STATUS: "/donationChangeStatus",
  DONATION_DELETE: "/deleteDonation",

  //blogs management
  ADD_BLOG: "/addBlog",
  EDIT_BLOG: "/editBlog",
  BLOGS_LIST: "/blogsList",
  BLOG_DETAIL: "/blogDetail",
  BLOG_STATUS: "/blogChangeStatus",
  BLOG_DELETE: "/deleteBlog",

  //contents management
  GET_CONTENTS: "/get_content",
  EDIT_CONTENT: "/edit_content",

  //profile and change password
  PROFILE_DETAIL: "/profile",
  PROFILE_EDIT: "/editProfile",
  CHANGE_PASSWORD: "/auth/changePassword",
};

const ENDPOINTS = {
  ...adminApiEndURL,
};

export default ENDPOINTS;
