import React from "react";

interface IProps {
    children: React.ReactNode
    title: string
}

const Wrapper: React.FC<IProps> = ({ children, title }) => {
    return (
        <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled">
            <div className="d-flex flex-column flex-root">
                <div className="page d-flex flex-row flex-column-fluid">
                    <div className="wrapper d-flex flex-column flex-row-fluid ps-0 pb-15">
                        <div className="container-fluid">
                            <div className="row my-4">
                                <div className="col-12">
                                    <div className="title-blk">
                                        <h1 className="text-dark fw-bolder my-1 fs-2">
                                            {title}
                                            <small className="text-muted fs-6 fw-normal ms-1" />
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Wrapper